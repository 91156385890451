import { Injectable } from '@angular/core';
import { escape } from 'lodash';
import { TranslateService } from '@ngx-translate/core';
import { COMPONENT_TYPE } from '../../constants/component_type';
import {
  AdwordsSourceComponentData,
  AnalyticsSourceComponentData,
  AnyComponentData,
  BigQuerySourceComponentData,
  CloudStorageDestinationComponentData,
  CloudStorageSourceComponentData,
  DatabaseDestinationComponentData,
  DatabaseSourceComponentData,
  FacebookAdsInsightsSourceComponentData,
  RECORD_DELIMITER,
  RECORD_TYPE,
  RestApiDestinationComponentData,
  RestApiSourceComponentData,
  SalesforceSourceComponentData,
} from '../../package-designer/package.models';
import { ComponentTypeItem } from '../../constants/component_types';
import { ConnectionTypeName } from '../../connections/connection.models';

export interface Tag {
  name: string;
}

function getValueName(key: string, value: any): string {
  let newValue = value;
  const $label = document.querySelector(`[for="${key}"]`);
  if (!$label) {
    return '';
  }
  const $next = $label.nextElementSibling;
  if ($next.matches('.btn-group-select')) {
    if (typeof value === 'object') {
      const keys = Object.keys(value);
      if (keys.length > 0) {
        newValue = (($next.querySelector(`[value="${keys[0]}"]`) as HTMLElement) || {}).innerText;
      }
    } else {
      newValue = (($next.querySelector(`[value="${value}"]`) as HTMLElement) || {}).innerText;
    }
  } else if ($next.matches('xp-select')) {
    if (($next.querySelector('.mat-mdc-select-value') as HTMLElement).querySelector('.mat-mdc-select-placeholder')) {
      newValue = '';
    } else {
      newValue = (($next.querySelector('.mat-mdc-select-value') as HTMLElement) || {}).innerText;
    }
  } else if ($next.matches('.form-group-options')) {
    if (!$next.querySelector('input[type=radio]:checked')) {
      newValue = '';
    } else {
      newValue = (($next.querySelector('input[type=radio]:checked').nextElementSibling as HTMLElement) || {}).innerText;
    }
  }
  return typeof newValue === 'object' ? '' : newValue;
}

function byString(o: any, s: string): any {
  let newS = s;
  let newO = o;
  newS = newS.replace(/\[(\w+)\]/g, '.$1');
  newS = newS.replace(/^\./, '');
  const a = newS.split('.');
  for (let i = 0, n = a.length; i < n; i += 1) {
    const k = a[i];
    if (k in o) {
      newO = o[k];
    } else {
      return null;
    }
  }
  return newO;
}

function generateTags(
  key: string,
  label: string,
  component: AnyComponentData,
  translationPath?: string,
  translate?: TranslateService,
): Tag[] {
  const tags: Tag[] = [];
  const value = byString(component, key);
  if (translationPath && value) {
    tags.push({
      name: `<span>${escape(label)}</span>: <b>${escape(translate.instant(translationPath + value))}</b>`,
    });
    return tags;
  }
  if (component[key] && component[key].length > 0) {
    tags.push({
      name: `<span>${escape(label)}</span>: <b>${escape(getValueName(key, component[key]))}</b>`,
    });
  } else if (value || value === '') {
    if (value === '') {
      return tags;
    }
    tags.push({ name: `<span>${escape(label)}</span>: <b>${escape(getValueName(key, value))}</b>` });
  } else if (label) {
    tags.push({ name: `<b>${escape(label)}</b>` });
  }

  return tags;
}

@Injectable({
  providedIn: 'root',
})
export class ComponentFormTagsService {
  constructor(private translate: TranslateService) {}
  public getTags(component: AnyComponentData, componentItem: ComponentTypeItem): Tag[] {
    let tags: Tag[] = [];

    const cloudStorageSourceComponent = component as CloudStorageSourceComponentData;
    const cloudStorageDestinationComponent = component as CloudStorageDestinationComponentData;
    const databaseSourceComponent = component as DatabaseSourceComponentData;
    const analyticsSourceComponent = component as AnalyticsSourceComponentData;
    const adwordsSourceComponent = component as AdwordsSourceComponentData;
    const facebookSourceComponent = component as FacebookAdsInsightsSourceComponentData;
    const restApiSourceComponent = component as RestApiSourceComponentData;
    const restApiDestinationComponent = component as RestApiDestinationComponentData;
    const databaseDestinationComponent = component as DatabaseDestinationComponentData;
    const bigQuerySourceComponent = component as BigQuerySourceComponentData;

    // eslint-disable-next-line default-case
    switch (componentItem.componentType) {
      case COMPONENT_TYPE.CLOUD_STORAGE_SOURCE_COMPONENT:
        if (
          cloudStorageSourceComponent.connection &&
          [ConnectionTypeName.softlayer, ConnectionTypeName.rackspace, ConnectionTypeName.swift].indexOf(
            cloudStorageSourceComponent.connection.type,
          ) > -1
        ) {
          tags = [...tags, ...generateTags('bucket', 'Container', cloudStorageSourceComponent)];
        } else if (
          cloudStorageSourceComponent.connection &&
          [ConnectionTypeName.s3, ConnectionTypeName.gs, ConnectionTypeName.gsv2].indexOf(
            cloudStorageSourceComponent.connection.type,
          ) > -1
        ) {
          tags = [...tags, ...generateTags('bucket', 'Bucket', cloudStorageSourceComponent)];
        }
        tags = [...tags, ...generateTags('path', 'Path', cloudStorageSourceComponent)];
        tags = [...tags, ...generateTags('record_delimiter', 'Record delimiter', cloudStorageSourceComponent)];
        tags = [...tags, ...generateTags('record_type', 'Record type', cloudStorageSourceComponent)];
        if (cloudStorageSourceComponent.record_type === RECORD_TYPE.DELIMITED) {
          tags = [
            ...tags,
            ...generateTags('record_settings.field_delimiter', 'Field delimiter', cloudStorageSourceComponent),
          ];
          tags = [
            ...tags,
            ...generateTags('record_settings.text_qualifier', 'String qualifier', cloudStorageSourceComponent),
          ];
        }
        if (
          cloudStorageSourceComponent.record_delimiter === RECORD_DELIMITER.EOF &&
          cloudStorageSourceComponent.record_type === RECORD_TYPE.JSON
        ) {
          tags = [...tags, ...generateTags('json_path', 'Json path', cloudStorageSourceComponent)];
        }
        if (
          cloudStorageSourceComponent.connection &&
          (cloudStorageSourceComponent.connection.type === ConnectionTypeName.s3 ||
            cloudStorageSourceComponent.connection.type === ConnectionTypeName.gs ||
            cloudStorageSourceComponent.connection.type === ConnectionTypeName.gsv2 ||
            cloudStorageSourceComponent.connection.type === ConnectionTypeName.sftp ||
            cloudStorageSourceComponent.connection.type === ConnectionTypeName.ftps ||
            cloudStorageSourceComponent.connection.type === ConnectionTypeName.azureBlobStorage)
        ) {
          tags = [...tags, ...generateTags('before_action', 'Source action', cloudStorageSourceComponent)];

          if (
            cloudStorageSourceComponent.before_action === 'copy' ||
            cloudStorageSourceComponent.before_action === 'copy_manifest'
          )
            tags = [...tags, ...generateTags('char_encoding', 'Character Encoding', cloudStorageSourceComponent)];
        }
        break;

      case COMPONENT_TYPE.DATABASE_SOURCE_COMPONENT:
        tags = [...tags, ...generateTags('access_mode', 'Access mode', databaseSourceComponent)];
        if ((databaseSourceComponent as DatabaseSourceComponentData).access_mode === 'table') {
          tags = [...tags, ...generateTags('schema_name', 'Schema', databaseSourceComponent)];
          tags = [...tags, ...generateTags('table_name', 'Table/view', databaseSourceComponent)];
          tags = [...tags, ...generateTags('where_clause', 'Where clause', databaseSourceComponent)];
          tags = [
            ...tags,
            ...generateTags('split_by_column_name', 'Split query by key column', databaseSourceComponent),
          ];
        } else {
          tags = [...tags, ...generateTags('query', 'Query', databaseSourceComponent)];
        }
        if (databaseSourceComponent.split_by_column_name?.length > 0) {
          tags = [...tags, ...generateTags('parallel', 'Max parallel connections', databaseSourceComponent)];
        }

        tags = [...tags, ...generateTags('before_action', 'Source action', databaseSourceComponent)];
        break;

      case COMPONENT_TYPE.AMAZON_REDSHIFT_SOURCE_COMPONENT:
        tags = [...tags, ...generateTags('access_mode', 'Access mode', component)];
        if ((component as DatabaseSourceComponentData).access_mode === 'table') {
          tags = [...tags, ...generateTags('schema_name', 'Schema', component)];
          tags = [...tags, ...generateTags('table_name', 'Table', component)];
          tags = [...tags, ...generateTags('where_clause', 'Where clause', component)];
        } else {
          tags = [...tags, ...generateTags('query', 'Query', component)];
        }
        break;

      case COMPONENT_TYPE.SPANNER_SOURCE_COMPONENT:
      case COMPONENT_TYPE.BIG_QUERY_SOURCE_COMPONENT:
        tags = [...tags, ...generateTags('access_mode', 'Access mode', component)];
        if ((component as DatabaseSourceComponentData).access_mode === 'table') {
          tags = [...tags, ...generateTags('table_name', 'Table', component)];
        } else {
          tags = [...tags, ...generateTags('query', 'Query', component)];
        }
        break;
      case COMPONENT_TYPE.NETSUITE_SOURCE_COMPONENT:
        tags = [...tags, ...generateTags('table_name', 'Table', component)];
        break;
      case COMPONENT_TYPE.MONGO_SOURCE_COMPONENT:
        tags = [...tags, ...generateTags('table_name', 'Source collection', component)];
        tags = [...tags, ...generateTags('where_clause', 'Filter query', component)];
        break;

      case COMPONENT_TYPE.CLOUD_STORAGE_DESTINATION_COMPONENT:
        if (
          cloudStorageDestinationComponent.connection &&
          [ConnectionTypeName.s3, ConnectionTypeName.gs, ConnectionTypeName.gsv2].indexOf(
            cloudStorageDestinationComponent.connection.type,
          ) > -1
        ) {
          tags = [...tags, ...generateTags('bucket', 'Bucket', cloudStorageDestinationComponent)];
        }
        tags = [...tags, ...generateTags('path', 'Directory', cloudStorageDestinationComponent)];
        tags = [...tags, ...generateTags('file_names', 'File pattern', cloudStorageDestinationComponent)];

        if (cloudStorageDestinationComponent.operation_type === 'copy_files') {
          tags = [...tags, ...generateTags('char_encoding', 'Character Encoding', cloudStorageDestinationComponent)];
        }

        break;

      case COMPONENT_TYPE.BING_ADS_SOURCE_COMPONENT:
        tags = [...tags, ...generateTags('api_version', 'API version', component)];
        tags = [...tags, ...generateTags('report_type', 'Report type', component)];
        tags = [...tags, ...generateTags('report_aggregation_type', 'Unit of time', component)];
        tags = [...tags, ...generateTags('report_date_range_type', 'Date range', component)];
        tags = [...tags, ...generateTags('report_on', 'Report on', component)];
        break;

      case COMPONENT_TYPE.ANALYTICS_SOURCE_COMPONENT:
        tags = [...tags, ...generateTags('report_on', 'Report on', analyticsSourceComponent)];
        tags = [...tags, ...generateTags('report_date_range_type', 'Date Range', analyticsSourceComponent)];

        if (analyticsSourceComponent.report_date_range_type === 'CUSTOM_DATE') {
          tags = [...tags, ...generateTags('report_date_range_min', 'From date', analyticsSourceComponent)];
          tags = [...tags, ...generateTags('report_date_range_max', 'To date', analyticsSourceComponent)];
        }
        break;
      case COMPONENT_TYPE.ADWORDS_SOURCE_COMPONENT:
        tags = [...tags, ...generateTags('report_type', 'Report type', adwordsSourceComponent)];
        tags = [...tags, ...generateTags('report_date_range_type', 'Date Range', adwordsSourceComponent)];

        if (adwordsSourceComponent.report_date_range_type === 'CUSTOM_DATE') {
          tags = [...tags, ...generateTags('report_date_range_min', 'From date', adwordsSourceComponent)];
          tags = [...tags, ...generateTags('report_date_range_max', 'To date', adwordsSourceComponent)];
        }
        tags = [...tags, ...generateTags('customer_ids', 'Customer ids', adwordsSourceComponent)];
        break;
      case COMPONENT_TYPE.SALESFORCE_SOURCE_COMPONENT:
        tags = [...tags, ...generateTags('access_mode', 'Access mode', component)];
        if ((component as SalesforceSourceComponentData).access_mode === 'object') {
          tags = [...tags, ...generateTags('object_name', 'Object', component)];
        } else {
          tags = [...tags, ...generateTags('query', 'Query', component)];
        }

        break;
      case COMPONENT_TYPE.SALESFORCE_DESTINATION_COMPONENT:
        tags = [...tags, ...generateTags('object_name', 'Object', component)];
        tags = [...tags, ...generateTags('operation_type', 'Operation', component)];

        break;

      case COMPONENT_TYPE.SALESFORCE_SOAP_DESTINATION_COMPONENT:
        tags = [...tags, ...generateTags('object_name', 'Object', component)];
        tags = [...tags, ...generateTags('operation_type', 'Operation', component)];

        break;

      case COMPONENT_TYPE.NETSUITE_SOAP_DESTINATION_COMPONENT:
        tags = [...tags, ...generateTags('object_name', 'Object', component)];
        tags = [...tags, ...generateTags('operation_type', 'Operation', component)];

        break;

      case COMPONENT_TYPE.FACEBOOK_ADS_DESTINATION_COMPONENT:
      case COMPONENT_TYPE.TIKTOK_ADS_DESTINATION_COMPONENT:
        tags = [...tags, ...generateTags('object_name', 'Object', component)];
        tags = [...tags, ...generateTags('operation_type', 'Operation', component)];

        break;
      case COMPONENT_TYPE.NETSUITE_DESTINATION_COMPONENT:
        tags = [...tags, ...generateTags('object_name', 'Object', component)];
        tags = [...tags, ...generateTags('operation_type', 'Operation', component)];

        break;

      case COMPONENT_TYPE.FACEBOOK_ADS_INSIGHTS_SOURCE_COMPONENT:
        // eslint-disable-next-line no-case-declarations
        let accountsText;

        if (facebookSourceComponent.report_on === 'all') {
          accountsText = 'all accounts';
        } else {
          accountsText = `${
            facebookSourceComponent.ad_accounts.length +
            (facebookSourceComponent.ad_accounts.length === 1 ? ' account' : ' accounts')
          } selected`;
        }

        tags.push({ name: `${`<span>Ad accounts</span>: <b>`}${accountsText}</b>` });

        tags = [...tags, ...generateTags('level', 'Level', facebookSourceComponent)];
        tags = [...tags, ...generateTags('report_date_range_type', 'Date Range', facebookSourceComponent)];

        if (facebookSourceComponent.report_date_range_type === 'CUSTOM_DATE') {
          tags = [...tags, ...generateTags('report_date_range_min', 'From date', facebookSourceComponent)];
          tags = [...tags, ...generateTags('report_date_range_max', 'To date', facebookSourceComponent)];
        }

        break;
      case COMPONENT_TYPE.REST_API_SOURCE_COMPONENT:
        tags = [...tags, ...generateTags('method', 'Method', restApiSourceComponent)];
        if (restApiSourceComponent.url && restApiSourceComponent.url.length > 0) {
          tags = [...tags, ...generateTags('url', 'URL', restApiSourceComponent)];
        }
        if (restApiSourceComponent.use_pagination) {
          tags = [...tags, ...generateTags('', 'Use pagination', restApiSourceComponent)];
        }
        tags = [
          ...tags,
          ...generateTags(
            'response_type',
            'Response type',
            restApiSourceComponent,
            'rest-api-source-editor.form.selects.response_type.options.',
            this.translate,
          ),
        ];
        break;

      case COMPONENT_TYPE.REST_API_DESTINATION_COMPONENT:
        tags = [...tags, ...generateTags('method', 'Method', restApiDestinationComponent)];
        if (restApiDestinationComponent.url && restApiDestinationComponent.url.length > 0) {
          tags = [...tags, ...generateTags('url', 'URL', restApiDestinationComponent)];
        }
        tags = [
          ...tags,
          // ...generateTags(
          //   'request_type',
          //   'Request type',
          //   restApiDestinationComponent,
          //   'rest-api-source-editor.form.selects.request_type.options.',
          //   this.translate,
          // ),
        ];
        break;

      case COMPONENT_TYPE.JOIN_COMPONENT:
        tags = [...tags, ...generateTags('join_type', 'Type', component)];
        tags = [...tags, ...generateTags('optimization_type', 'Optimization', component)];
        break;

      case COMPONENT_TYPE.SNOWFLAKE_DESTINATION_COMPONENT:
      case COMPONENT_TYPE.AMAZON_REDSHIFT_DESTINATION_COMPONENT:
      case COMPONENT_TYPE.DATABASE_DESTINATION_COMPONENT:
      case COMPONENT_TYPE.BIG_QUERY_DESTINATION_COMPONENT:
      case COMPONENT_TYPE.SPANNER_DESTINATION_COMPONENT:
        if (databaseDestinationComponent.warehouse && databaseDestinationComponent.warehouse.length > 0) {
          tags = [...tags, ...generateTags('warehouse', 'Warehouse', databaseDestinationComponent)];
        }
        if (databaseDestinationComponent.database && databaseDestinationComponent.database.length > 0) {
          tags = [...tags, ...generateTags('database', 'Database', databaseDestinationComponent)];
        }
        if (databaseDestinationComponent.schema_name && databaseDestinationComponent.schema_name.length > 0) {
          tags = [...tags, ...generateTags('schema_name', 'Schema', databaseDestinationComponent)];
        }
        tags = [...tags, ...generateTags('table_name', 'Table', databaseDestinationComponent)];
        tags = [...tags, ...generateTags('operation_type', 'Operation', databaseDestinationComponent)];
        break;
      case COMPONENT_TYPE.MONGO_DESTINATION_COMPONENT:
        tags = [...tags, ...generateTags('table_name', 'Collection', databaseDestinationComponent)];
        tags = [...tags, ...generateTags('operation_type', 'Operation', databaseDestinationComponent)];
        break;
    }

    if (
      componentItem.componentType === COMPONENT_TYPE.BIG_QUERY_SOURCE_COMPONENT &&
      bigQuerySourceComponent.access_mode === 'query'
    ) {
      tags = [...tags, ...generateTags('use_legacy_sql', 'Use Legacy SQL', bigQuerySourceComponent)];
      tags = [...tags, ...generateTags('stored_procedure', 'Stored Procedure Query', bigQuerySourceComponent)];
    }

    if (
      (componentItem.componentType === COMPONENT_TYPE.SPANNER_SOURCE_COMPONENT ||
        componentItem.componentType === COMPONENT_TYPE.NETSUITE_SOURCE_COMPONENT) &&
      (component as DatabaseSourceComponentData).access_mode === 'table'
    ) {
      tags = [...tags, ...generateTags('where_clause', 'Where clause', component)];
    }

    return tags;
  }
}
