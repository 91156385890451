import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';

import { AppState } from '../../store';
import { environment } from '../../../environments/environment';
import { ListParams } from '../../common/helper/query-params-generic-list.helper';
import { QueryParams } from '../../models/http.models';

export interface NetsuiteSchema {
  [key: string]: {
    schema_alias: string;
    fields: {
      name: string;
      type: string;
    }[];
    searchFields: {
      name: string;
      type: string;
    }[];
  };
}

@Injectable({
  providedIn: 'root',
})
export class NetsuiteResource {
  private apiRoot: string = `${environment.API_URL}`;

  constructor(
    private http: HttpClient,
    private store: Store<AppState>,
  ) {}

  get(params?: ListParams): Observable<NetsuiteSchema> {
    return this.http.get<NetsuiteSchema>(`${this.apiRoot}/netsuite/schema.json`, {
      params: params as QueryParams,
    });
  }
}
