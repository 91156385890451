import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { first, map, tap } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { v4 as uuidv4 } from 'uuid';
import { forEachRight } from 'lodash';
import { AppState } from '../../../store';
import {
  selectConnectionSchema,
  selectConnectionSchemaData,
  selectConnectionSchemaErrors,
  selectConnectionsIsLoadingSchemaFlag,
} from '../../../connections/store/connections.selectors';
import {
  AdwordsSourceComponentData,
  AllComponentData,
  AmazonRedshiftSourceComponentData,
  Analytics4SourceComponentData,
  AnalyticsSourceComponentData,
  BigQuerySourceComponentData,
  BingAdsSourceComponentData,
  CloudStorageSourceComponentData,
  ConnectionSchemaField,
  DatabaseSourceComponentData,
  ExecuteSQLComponentData,
  FacebookAdsInsightsSourceComponentData,
  MongoSourceComponentData,
  NetsuiteSourceComponentData,
  RestApiSourceComponentData,
  SalesforceSourceComponentData,
  SpannerSourceComponentData,
} from '../../package.models';
import {
  DEFAULT_SCHEMA_SETTINGS,
  getSettingsForComponent,
  SchemaImporterSettings,
} from '../../helpers/schema-importer-settings.helper';
import { ComponentTypeItem } from '../../../constants/component_types';
import { getConnectionSchema } from '../../../connections/store/connections.actions';
import { getDataForSchemaImporter } from '../../helpers/schema.helpers';
import { AuthorizationGuard } from '../../../common/services/authorization.guard';
import { COMPONENT_TYPE } from '../../../constants/component_type';
import { openPackageVariablesModal } from '../../store/package-designer.actions';

function createField(field: Partial<ConnectionSchemaField>): ConnectionSchemaField {
  return {
    alias: field.alias,
    data_type: field.data_type,
    name: field.name,
    disableSortable: field.disableSortable,
    disableTypeEdit: field.disableTypeEdit,
    required: field.required,
    predefined: field.predefined,
    _category: field.category,
    editable: field.editable,
  };
}

export function adjustRelationshipField(field: ConnectionSchemaField): ConnectionSchemaField {
  return {
    ...field,
    alias: field.alias
      .replace(/\[\*\]\./g, '_')
      .replace(/\./g, '_')
      .replace(/\s/g, '')
      .replace(/_+/g, '_'),
  };
}

export type SchemaImporterComponentData =
  | AdwordsSourceComponentData
  | AmazonRedshiftSourceComponentData
  | AnalyticsSourceComponentData
  | BigQuerySourceComponentData
  | BingAdsSourceComponentData
  | CloudStorageSourceComponentData
  | DatabaseSourceComponentData
  | ExecuteSQLComponentData
  | FacebookAdsInsightsSourceComponentData
  | MongoSourceComponentData
  | NetsuiteSourceComponentData
  | SpannerSourceComponentData
  | SalesforceSourceComponentData
  | RestApiSourceComponentData
  | Analytics4SourceComponentData;

@Component({
  selector: 'schema-importer',
  template: `
    <div class="columns-schema-container" [ngClass]="{ disabled: isDisabled }">
      <xp-loader *ngIf="isLoading$ | async"></xp-loader>
      <div
        *ngIf="errorMessage"
        class="mat-snack-bar-container errors-notify alert notify-template warning no-shadow alert-warning"
      >
        <div class="message-container" [ngStyle]="{ display: 'inline-block' }">
          <p class="message" innerHTML="{{ errorMessage }}"></p>
          @if (errorMessage.includes('Parsing error. Please verify that you are using the correct function')) {
            <a (click)="openVariableExpressionEditor()">Click here</a> to review and update the package variable
            expression.
          }
        </div>
      </div>
      <div
        *ngIf="showReplaceErrorMessage && !(isLoading$ | async)"
        [ngClass]="{ 'show-notify': showReplaceErrorMessage }"
        class="mat-snack-bar-container errors-notify alert notify-template warning no-shadow alert-warning"
      >
        <div class="message-container" [ngStyle]="{ display: 'inline-block' }">
          <p class="message" *ngIf="showReplaceErrorMessage">
            Detected source schema doesn't match selected schema. <a (click)="replaceFields()">Click here</a> to replace
            the selected fields with new schema.
          </p>
        </div>
      </div>
      <div *ngIf="!(isLoading$ | async)" class="columns-schema">
        <div class="columns-schema-items-container">
          <div class="columns-schema-item">
            <div class="columns-schema-header">
              <div class="title">
                Available fields
                <span class="items-count badge">{{
                  (connectionSchema$ | async).length + predefinedFields.length
                }}</span>
              </div>
              <button
                class="btn btn-gray"
                (click)="selectAll()"
                *ngIf="availableFieldsOptions.allowSelectAllAvailableFields"
                [disabled]="fields.length === (connectionSchema$ | async).length + predefinedFields.length"
              >
                Select all
              </button>
            </div>
            <connection-schema
              [fields]="connectionSchema$ | async"
              (addField)="addField($event)"
              [settings]="availableFieldsOptions"
              [selectedFields]="fields"
              [predefinedFields]="predefinedFields"
              [isLoading]="isConnectionSchemaLoading"
              id="available-fields"
            ></connection-schema>
          </div>
          <div class="split-handler"></div>
          <div class="columns-schema-item">
            <div class="columns-schema-header">
              <div class="title">
                Selected fields <span class="items-count badge">{{ fields.length }}</span>
              </div>
              <button class="btn btn-gray" (click)="removeAll()" [disabled]="fields.length === 0">Remove all</button>
            </div>
            <connection-schema
              [fields]="fieldsWithId"
              [settings]="selectedFieldsOptions"
              [hideTabs]="true"
              [isLoading]="isConnectionSchemaLoading"
              [ignoreFieldNameValidation]="ignoreFieldNameValidation"
              (addField)="addFieldBetween($event)"
              (addFieldToEnd)="addFieldToEnd()"
              (updateFields)="onUpdateFields($event)"
              (removeField)="removeField($event)"
              (updateValidation)="updateValidation.emit($event)"
              id="selected-fields"
            ></connection-schema>
          </div>
        </div>

        <schema-importer-data-preview
          *ngIf="component.hasDataPreview"
          [data]="data$ | async"
          [component]="component"
          [rawComponent]="rawComponent"
          (refreshData)="refreshData()"
          [isPreviewLoading]="isLoading$ | async"
          [options]="selectedFieldsOptions"
          [dataPreviewDisabled]="dataPreviewDisabled"
        ></schema-importer-data-preview>
      </div>
    </div>
  `,
})
export class SchemaImporterComponent implements OnInit, OnDestroy, OnChanges {
  @Input() fields: ConnectionSchemaField[] = [];
  @Input() isDisabled: boolean = false;
  @Input() dataPreviewDisabled: boolean = false;
  @Input() component: ComponentTypeItem;
  @Input() rawComponent: SchemaImporterComponentData;
  @Input() isConnectionSchemaLoading = false;
  @Input() ignoreFieldNameValidation: boolean = false;
  @Input() errorMessage: string = '';
  @Output() fieldsChange = new EventEmitter<ConnectionSchemaField[]>();
  @Output() updateValidation = new EventEmitter<boolean>();
  @Output() refreshDataEvent = new EventEmitter();

  availableFields: ConnectionSchemaField[] = [];
  fieldsWithId: ConnectionSchemaField[] = [];

  error$ = this.store.select(selectConnectionSchemaErrors);
  errorTexts = [];
  isLoading$ = this.store.select(selectConnectionsIsLoadingSchemaFlag);
  data$ = this.store.select(selectConnectionSchemaData);
  connectionSchema$ = this.store.select(selectConnectionSchema).pipe(
    map((fields) => fields.map((field) => ({ ...field, id: uuidv4() }))),
    tap((fields) => (this.availableFields = fields)),
  );
  errorsSubscriber: Subscription;
  connectionSchemaSubscriber: Subscription;
  selectedFieldsOptions: Partial<SchemaImporterSettings> = { ...DEFAULT_SCHEMA_SETTINGS, showAlias: true };
  availableFieldsOptions: Partial<SchemaImporterSettings> = {
    ...DEFAULT_SCHEMA_SETTINGS,
    add: true,
    showSourceDesignatorInSelectedFields: true,
  };
  showReplaceErrorMessage = false;
  add_counter = 0;
  predefinedFields = [];

  constructor(
    private store: Store<AppState>,
    private authGuard: AuthorizationGuard,
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    let predefinedFieldsHaveChanged = false;

    if (changes.component?.currentValue) {
      const settings = getSettingsForComponent(this.component, this.rawComponent as AllComponentData);

      if (settings.preDefinedFields.length !== this.selectedFieldsOptions.preDefinedFields.length) {
        predefinedFieldsHaveChanged = true;
      }
      this.selectedFieldsOptions = { ...this.selectedFieldsOptions, ...settings };
      this.availableFieldsOptions = { ...this.availableFieldsOptions, ...settings };
      this.availableFieldsOptions.sourceDesginatorName = settings.sourceDesginatorName;
      // if you can't remove a specific field, you can't add specific field (all or nothing behavior)
      this.availableFieldsOptions.add = settings.allowToRemoveSelectedFields;
    }

    if (changes.fields?.currentValue || predefinedFieldsHaveChanged) {
      this.fieldsWithId = this.fields.map((item) => ({ ...item, id: uuidv4() }));
      this.checkPresenceOfFields();
      this.setPredefinedFields();
    }
  }

  ngOnInit() {
    this.errorsSubscriber = this.error$.subscribe((error) => {
      if (!error) {
        this.errorTexts = [];
      } else {
        this.errorTexts = [error.message];
      }
    });
    this.fieldsWithId = this.fields.map((item) => ({ ...item, id: uuidv4() }));
    this.checkPresenceOfFields();
    this.setPredefinedFields();
  }

  addField({ field }) {
    this.fields = [...this.fields, adjustRelationshipField(field)];
    this.fieldsChange.emit(this.fields);
  }

  createNewField(): ConnectionSchemaField {
    let fieldName = `FIELD_NAME_${this.add_counter}`;

    const getNextField = () => {
      const foundField = this.fields.find((item) => item.name === fieldName);
      if (foundField) {
        this.add_counter += 1;
        fieldName = `FIELD_NAME_${this.add_counter}`;
        getNextField();
      }
    };
    getNextField();

    const newField: ConnectionSchemaField = {
      name: fieldName,
      alias: `FIELD_ALIAS_${this.add_counter}`,
      data_type: 'string',
      id: uuidv4(),
    };

    this.add_counter += 1;

    if (this.availableFields[0]?.category) {
      newField.category = this.availableFields[0].category;
    }

    return newField;
  }

  addFieldToEnd() {
    const newField = this.createNewField();
    this.fields = [...this.fields, newField];
    this.fieldsChange.emit(this.fields);
  }

  addFieldBetween({ index }) {
    const newField = this.createNewField();
    if (index === 0) {
      this.fields = [newField, ...this.fields];
    } else if (index > 0) {
      this.fields = [...this.fields];
      this.fields.splice(index, 0, newField);
    } else {
      this.fields = [...this.fields, newField];
    }
    this.fieldsChange.emit(this.fields);
  }

  removeField(field: ConnectionSchemaField) {
    this.fields = this.fields.filter((item) => item.name !== field.name);
    this.fieldsChange.emit(this.fields);
  }

  onUpdateFields(fields: ConnectionSchemaField[]) {
    this.fieldsChange.emit(fields);
  }

  selectAll() {
    this.connectionSchema$.pipe(first()).subscribe((fields) => {
      this.fields = [...fields].map(adjustRelationshipField);
      this.fieldsChange.emit(this.fields);
    });
  }

  removeAll() {
    this.fields = [];
    this.fieldsChange.emit(this.fields);
  }

  checkPresenceOfFields() {
    if (this.connectionSchemaSubscriber) {
      this.connectionSchemaSubscriber.unsubscribe();
    }

    this.connectionSchemaSubscriber = this.connectionSchema$.subscribe((availableFields) => {
      const fields = availableFields;
      const missedFields = this.fields.filter(
        (field) => ![...fields, ...this.predefinedFields].find((availableField) => field.name === availableField.name),
      );
      let isWrongOrder = false;

      if (
        this.component.componentType === COMPONENT_TYPE.SALESFORCE_SOURCE_COMPONENT &&
        (this.rawComponent as SalesforceSourceComponentData).access_mode === 'query'
      ) {
        this.fields.forEach((field, index) => {
          const isDifferentIndex =
            [...fields, ...this.predefinedFields].findIndex((item) => item.name === field.name) !== index;
          if (isDifferentIndex) {
            isWrongOrder = true;
          }
        });
      }

      if (missedFields.length || isWrongOrder) {
        this.showReplaceErrorMessage = true;
      } else {
        this.showReplaceErrorMessage = false;
      }
    });
  }

  removeError() {
    this.showReplaceErrorMessage = false;
  }

  replaceFields() {
    this.removeError();
    this.removeAll();
    this.selectAll();
  }

  refreshData() {
    let schemaRequestData = getDataForSchemaImporter(
      this.rawComponent as AllComponentData,
      this.component.componentType,
      this.authGuard.account.account_id,
    );

    this.store.dispatch(
      getConnectionSchema({
        onlyData: true,
        connectionId: this.rawComponent.connection?.id,
        connectionType: this.rawComponent.connection?.id ? this.rawComponent.connection?.type : 'curl',
        schemaRequestData,
      }),
    );

    this.refreshDataEvent.emit();
  }

  setPredefinedFields() {
    const fieldsToRemove = [];
    this.predefinedFields = [];

    // remove any existing predefined field that don't exist in predefined list
    this.fields.forEach((field) => {
      if (field.predefined) {
        const predefinedField = this.selectedFieldsOptions.preDefinedFields.find((item) => item.name === field.name);
        if (!predefinedField || field.markedToBeRemoved) {
          fieldsToRemove.push(field);
        }
      }
    });

    fieldsToRemove.forEach((field) => {
      this.removeField(field);
    });

    // possibly wrong place to store the predefined fields
    forEachRight(this.selectedFieldsOptions.preDefinedFields, (field) => {
      const existingFieldIndex = this.fields.findIndex((item) => item.name === field.name);
      if (existingFieldIndex > -1) {
        // eslint-disable-next-line no-param-reassign
        field = { ...field, ...this.fields[existingFieldIndex] };
        this.fields = [...this.fields];
        this.fields.splice(existingFieldIndex, 1, createField(field));
        this.predefinedFields = [...this.predefinedFields, field];
      } else {
        if (field.required) {
          this.fieldsChange.emit([createField(field), ...this.fields]);
        }
        this.predefinedFields = [...this.predefinedFields, field];
      }
    });
  }

  openVariableExpressionEditor() {
    this.store.dispatch(openPackageVariablesModal());
  }

  ngOnDestroy() {
    if (this.errorsSubscriber) {
      this.errorsSubscriber.unsubscribe();
    }

    if (this.connectionSchemaSubscriber) {
      this.connectionSchemaSubscriber.unsubscribe();
    }
  }
}
