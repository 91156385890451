import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { v4 as uuidv4 } from 'uuid';
import { DesignerSchemaFieldI } from '../../../models/designer-schema-field.model';
import { CollectionSettings } from '../fields-collection.models';
import { ConnectionSchemaField, Field, Schema } from '../../../package.models';
import { findDestinationField, mergeFields } from '../../../helpers/fields.helpers';

@Component({
  selector: 'schema-mapping-hubspot-collection',
  template: `
    <h4>Attributes mapping</h4>
    <hr />
    <xp-fields-collection
      class="hubspot-fields-collection"
      [records]="recordsCopy"
      [collectionSettings]="collectionSettings"
      [isValid]="valid"
      (validityChange)="onFieldsValidityChange($event)"
      (recordsChange)="onRecordChange($event)"
      [columns]="['field_name', 'column_name']"
      duplicationValidationProp="column_name"
      duplicationValidationPropName="Destination column name"
      autofillTooltip="Auto-fill - maps input fields to corresponding Hubspot fields. Manually map unmapped fields afterwards.
&#13;
Clear unmapped - removes all unmapped fields."
    >
      <ng-template templateName="field_name" let-item>
        <xp-field-picker
          [value]="item.record.field_name"
          [index]="item.index"
          [schema]="schemas[0]"
          [fields]="(schemas[0] || {}).fields || []"
          [preventEmpty]="false"
          propName="field_name"
          (fieldChange)="onFieldChange($event, item.record, 'field_name')"
          class="fields-collection-editor"
        ></xp-field-picker>
      </ng-template>
      <ng-template templateName="field_name-header" let-item>
        <span>{{ 'schema-mapping-hubspot.headers.input-field' | translate }}</span>
      </ng-template>
      <ng-template templateName="column_name" let-item>
        <xp-field-picker
          [value]="item.record.column_name"
          [index]="item.index"
          [schema]="schemas[1]"
          [fields]="(schemas[1] || {}).fields || []"
          [isDuplicateError]="item.record.isDuplicateError"
          [preventEmpty]="false"
          propName="column_name"
          (fieldChange)="onFieldChange($event, item.record, 'column_name')"
          class="fields-collection-editor"
          fieldName="Destination Field"
          [ngClass]="{ readonly: item.record.required }"
        ></xp-field-picker>
      </ng-template>
      <ng-template templateName="column_name-header" let-item>
        <span>{{ 'schema-mapping-hubspot.headers.destination-field' | translate }}</span>
      </ng-template>
    </xp-fields-collection>

    @if (operationType === 'create') {
      <h4 class="middle-header">Associations mapping <span class="optional-text">(optional)</span></h4>
      <hr />
      <xp-fields-collection
        [records]="associationsRecordsCopy"
        [collectionSettings]="associationsCollectionSettings"
        [isValid]="true"
        (validityChange)="onAssociationFieldsValidityChange($event)"
        (recordsChange)="onAssociationRecordChange($event)"
        [columns]="associationsColumns"
        [hideAutofill]="true"
      >
        <ng-template templateName="association_id" let-item>
          <xp-field-picker
            [value]="item.record.association_id"
            [index]="item.index"
            [schema]="schemas[0]"
            [fields]="(schemas[0] || {}).fields || []"
            [preventEmpty]="false"
            propName="association_id"
            placeholder="Select a field"
            (fieldChange)="onAssociationFieldChange($event, item.record, 'association_id')"
            class="fields-collection-editor"
          ></xp-field-picker>
        </ng-template>
        <ng-template templateName="association_id-header" let-item>
          <span>Association object ID</span>
        </ng-template>
        <ng-template templateName="association_type_id" let-item>
          <xp-hubspot-association-type-picker
            [value]="item.record.association_type_id"
            [typeCategory]="item.record.association_type_category"
            [index]="item.index"
            [objectName]="objectName"
            (valueChange)="onAssociationFieldChange($event, item.record, 'association_type_id')"
            class="fields-collection-editor"
          ></xp-hubspot-association-type-picker>
        </ng-template>
        <ng-template templateName="association_type_id-header" let-item>
          <span>Association Type </span>
        </ng-template>
        <ng-template templateName="association_type_category" let-item>
          <xp-hubspot-association-category-picker
            [value]="item.record.association_type_category"
            [index]="item.index"
            (valueChange)="onAssociationFieldChange($event, item.record, 'association_type_category')"
            class="fields-collection-editor"
          ></xp-hubspot-association-category-picker>
        </ng-template>
        <ng-template templateName="association_type_category-header" let-item>
          <span>Association Category</span>
        </ng-template>
      </xp-fields-collection>
    }
  `,
  providers: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SchemaMappingHubspotCollectionComponent implements OnInit, OnChanges {
  @Input() records: DesignerSchemaFieldI[];
  @Input() associationsRecords: any[];
  @Input() valid: boolean;
  @Input() schemas: Schema[];
  @Input() fields: DesignerSchemaFieldI[];
  @Input() operationType: string;
  @Input() objectName: string;
  @Input() connectionType: string = 'hubspot';
  @Input() connectionId: number | string;
  @Input() upsertIdProperty: string;
  @Output() recordsChange = new EventEmitter();
  @Output() associationsRecordsChange = new EventEmitter();
  @Output() validityChange = new EventEmitter();

  idField = 'id';
  hsObjectIdField = 'hs_object_id';
  idFieldItem: Field & ConnectionSchemaField;

  collectionSettings: CollectionSettings;
  associationsCollectionSettings: CollectionSettings;
  recordsCopy: DesignerSchemaFieldI[] = [];
  associationsRecordsCopy: any[] = [];
  associationsColumns: string[] = ['association_id', 'association_type_category', 'association_type_id'];
  associationsValid: boolean;

  constructor() {}

  ngOnInit() {
    this.recordsCopy = [...this.records].map((item) => ({ ...item, id: uuidv4() }));
    this.associationsRecordsCopy = [...this.associationsRecords].map((item) => ({ ...item, id: uuidv4() }));

    const defaultCollectionSettings: CollectionSettings = {
      itemsPerPage: 10,
      emptyRecord: {
        field_name: '',
        column_name: '',
        field_type: '',
        FC_pristine: true,
      },
      parentSchemas: this.schemas,
      autoFillFns: [
        {
          func: this.autoFill.bind(this),
          text: 'Auto-fill',
        },
        {
          text: 'Clear unmapped',
          func: this.clearEmpty.bind(this),
        },
      ],
    };

    this.collectionSettings = { ...(this.collectionSettings || {}), ...defaultCollectionSettings };

    this.associationsCollectionSettings = {
      itemsPerPage: 10,
      allowEmptyCollection: true,
      addNewRecordWhenEmpty: true,
      addNewRecordWhenEmptyText: '+ Click here to add New Association',
      addNewRecordWhenEmptyColspan: 6,
      emptyRecord: {
        association_id: '',
        association_type_id: '',
        association_type_category: '',
        FC_pristine: true,
      } as any,
      autoFillFns: [],
    };

    if (this.operationType === 'update' || this.operationType === 'upsert') {
      this.addFieldOnUpdate();
    }

    if (this.operationType === 'create') {
      this.removeFieldsOnCreate();
    }

    if (this.recordsCopy.length === 0) {
      this.recordsCopy = ((this.schemas[0] || {}).fields || []).map((field) => ({
        field_name: field.name,
        id: uuidv4(),
        FC_pristine: false,
      }));
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.operationType && this.fields) {
      this.onOperationTypeChange(changes.operationType.currentValue, changes.operationType.previousValue);
    }

    if (changes.fields && changes.fields.currentValue) {
      this.fillRecords();
      this.fillParentSchemas();
    }

    if (changes.upsertIdProperty && changes.upsertIdProperty.currentValue) {
      this.updateUpsertIdProperty(changes.upsertIdProperty.previousValue);
    }
  }

  get availableColumns() {
    return ((this.schemas[0] || {}).fields || []).map((field) => field.name) || [];
  }

  onOperationTypeChange(newOperationType: string, oldOperationType: string) {
    this.fillParentSchemas();
    if (newOperationType !== oldOperationType) {
      if (newOperationType === 'update' || newOperationType === 'upsert') {
        setTimeout(() => {
          this.addFieldOnUpdate();

          if (newOperationType === 'update') {
            this.removeUpsertIdProperty(this.upsertIdProperty);
          }

          if (newOperationType === 'upsert') {
            this.updateUpsertIdProperty(this.idField);
          }
        });
        this.associationsRecords = [];
      } else {
        setTimeout(() => {
          this.removeFieldsOnCreate();
        });
      }
    }
  }

  removeUpsertIdProperty(valueToRemove: string) {
    if (valueToRemove) {
      this.recordsCopy = this.recordsCopy.filter((item) => item.column_name !== valueToRemove);
      this.onRecordChange({ records: this.recordsCopy });
    }
  }

  updateUpsertIdProperty(previousValue: string) {
    if (previousValue) {
      this.removeUpsertIdProperty(previousValue);
      const foundRecord = this.recordsCopy.find((item) => item.column_name === this.upsertIdProperty);
      if (foundRecord) {
        this.recordsCopy = this.recordsCopy.map((item) => ({ ...item, required: item.name === this.upsertIdProperty }));
      } else {
        this.addFieldByName(this.upsertIdProperty, true, true);
      }
      this.onRecordChange({ records: this.recordsCopy });
    }
  }

  removeFieldsOnCreate() {
    this.removeUpsertIdProperty(this.upsertIdProperty);
    this.removeUpsertIdProperty(this.idField);

    const field = this.schemas[1].fields.find((item) => item.name === this.idField);
    if (field) {
      this.schemas[1].fields = this.schemas[1].fields.filter((item) => item.name !== this.idField);
      this.idFieldItem = field;
    }

    const hsObjectIdRecord = this.recordsCopy.find((item) => item.column_name === this.hsObjectIdField);

    if (hsObjectIdRecord) {
      this.recordsCopy = this.recordsCopy.filter((item) => item.column_name !== this.hsObjectIdField);
      this.onRecordChange({ records: this.recordsCopy });
    }
  }

  addFieldOnUpdate() {
    const idProperty = this.operationType === 'upsert' ? this.upsertIdProperty : this.idField;
    const record = this.recordsCopy.find((item) => item.column_name === idProperty);
    if (!record) {
      this.addFieldByName(this.idField, true, true);
      this.onRecordChange({ records: this.recordsCopy });
    } else {
      this.recordsCopy = this.recordsCopy.map((item) =>
        item.column_name === idProperty ? { ...item, required: true } : item,
      );
      this.onRecordChange({ records: this.recordsCopy });
    }

    const field = this.schemas[1].fields.find((item) => item.name === idProperty);
    if (!field) {
      this.schemas[1].fields = [...this.schemas[1].fields, this.idFieldItem];
    }
  }

  fillRecords() {
    const requiredFields = {};
    this.recordsCopy = this.recordsCopy.map((record) => {
      let required;
      if (!requiredFields[record.column_name]) {
        if (this.operationType === 'delete') {
          required = true;
          requiredFields[record.column_name] = true;
        } else {
          const field = this.fields.find((item) => item.name === record.column_name) || {};
          if (
            (field && field.is_required) ||
            (field.name === this.idField && this.operationType === 'update') ||
            (field.name === this.upsertIdProperty && this.operationType === 'upsert')
          ) {
            required = true;
            requiredFields[record.column_name] = true;
          } else {
            required = false;
          }
        }
      }
      return {
        ...record,
        required,
      };
    });
  }

  fillParentSchemas() {
    const destinationSchema = {} as Schema;
    destinationSchema.id = `hubspot_fields_${this.objectName}`;
    destinationSchema.name = `hubspot_fields_${this.objectName}`;

    const objectsSchema = {} as Schema;
    objectsSchema.id = `objects_fields_${this.objectName}`;
    objectsSchema.name = `objects_fields_${this.objectName}`;

    this.fields.forEach((field) => {
      destinationSchema.fields = [
        ...(destinationSchema.fields || []),
        {
          name: field.name,
        },
      ];
      if (field && field.is_required) {
        const isFieldExisting =
          this.recordsCopy.find((item) => item.column_name === field.name) ||
          destinationSchema.fields.find((item) => item.column_name === field.name);
        if (!isFieldExisting) {
          if (this.operationType === 'insert') {
            let parent_field_name = '';
            if (this.schemas[0] && this.schemas[0].fields[0]) {
              parent_field_name = this.schemas[0].fields[0].name;
            }
            this.recordsCopy = [
              ...this.recordsCopy,
              {
                required: true,
                field_name: parent_field_name,
                column_name: field.name,
                field_type: field.type || field.data_type,
                id: uuidv4(),
              },
            ];
          }
        } else {
          isFieldExisting.required = this.isRequired();
        }
      }
    });

    this.schemas = [this.schemas[0], destinationSchema, objectsSchema];
  }

  setReference(value: string) {
    const record = this.recordsCopy.find((item) => item.column_name === value);
    if (record) {
      const field = this.fields.find((item) => item.name === value);
      if (field) {
        record.field_type = field.data_type;
      }
    }
  }

  clearEmpty() {
    let newRecords = this.recordsCopy.filter((item) => item.column_name && item.field_name);
    if (!newRecords.length) {
      newRecords = [this.collectionSettings.emptyRecord];
    }
    this.recordsCopy = newRecords;
    this.recordsChange.emit(newRecords);
  }

  autoFill() {
    if (!this.schemas[0] || !this.schemas[0].fields) {
      return;
    }

    const records: DesignerSchemaFieldI[] = [];
    mergeFields(this.recordsCopy, this.schemas[0].fields).forEach((field) => {
      const fieldName = (field as DesignerSchemaFieldI).field_name || field.name || '';
      let fieldDestination = findDestinationField(fieldName, this.fields) || {};
      const isIdField =
        fieldDestination &&
        (fieldDestination.name === this.idField || fieldDestination.name === this.upsertIdProperty) &&
        this.operationType === 'upsert';
      if (isIdField) {
        fieldDestination = {};
      }
      const record: DesignerSchemaFieldI = {
        field_name: fieldName,
        column_name: fieldDestination.name || '',
        field_type: fieldDestination.data_type || '',
        id: uuidv4(),
        FC_pristine: false,
      };

      record.required =
        (this.operationType === 'update' && field.column_name === this.idField) ||
        (this.operationType === 'upsert' && field.column_name === this.upsertIdProperty);

      if (!!record.column_name && records.find((item) => item.column_name === record.column_name)) {
        return;
      }

      records.push(record);
    });

    if (!records.length) {
      return this.recordsCopy;
    }
    return records;
  }

  isRequired() {
    return this.operationType !== 'upsert';
  }

  addFieldByName(fieldName, addFirst, isRequired) {
    const field = this.fields.find((item) => item.name === fieldName);
    let parent_field_name = '';
    if (this.schemas[0] && this.schemas[0].fields[0]) {
      parent_field_name = this.schemas[0].fields[0].name;
    }
    if (field) {
      const newRecord = {
        required: isRequired,
        field_name: parent_field_name,
        column_name: field.name,
        field_type: field.type || field.data_type,
        id: uuidv4(),
      };
      if (addFirst) {
        this.recordsCopy = [newRecord, ...this.recordsCopy];
      } else {
        this.recordsCopy = [...this.recordsCopy, newRecord];
      }
    }
  }

  onRecordChange({ records }) {
    if (!this.fields) {
      return;
    }
    this.recordsCopy = records;
    const columnMappings = records.map((record) => ({
      column_name: record.column_name,
      field_name: record.field_name,
      field_type: record.field_type,
    }));

    this.recordsChange.emit(columnMappings);
  }

  onFieldChange(value: string, record: DesignerSchemaFieldI, prop: keyof DesignerSchemaFieldI) {
    if (!this.fields || value === undefined || value === null || record[prop] === value) {
      return;
    }
    const newRecords = this.recordsCopy.map((item) => {
      if (item.id === record.id) {
        return {
          ...item,
          [prop]: value,
        };
      }
      return item;
    });
    this.recordsCopy = newRecords;

    if (prop === 'column_name') {
      this.setReference(value);
    }

    this.onRecordChange({
      records: newRecords,
    });
  }

  onAssociationRecordChange({ records }) {
    this.associationsRecordsCopy = records;
    this.associationsRecordsChange.emit(records);
  }

  onAssociationFieldChange(value: string, record: any, prop: keyof any) {
    if (!this.fields || value === undefined || value === null || record[prop] === value) {
      return;
    }
    const newRecords = this.associationsRecordsCopy.map((item) => {
      if (item.id === record.id) {
        return {
          ...item,
          [prop]: value,
        };
      }
      return item;
    });
    this.associationsRecordsCopy = newRecords;

    this.onAssociationRecordChange({
      records: newRecords,
    });
  }

  onAssociationFieldsValidityChange(value: boolean) {
    this.associationsValid = value;
  }

  onFieldsValidityChange(value: boolean) {
    this.validityChange.emit(value);
  }
}
