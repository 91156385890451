import { Component, Input, OnInit } from '@angular/core';

import { Store } from '@ngrx/store';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { AppState } from '../../store';
import { ContextHelpService } from '../services/context-help.service';
import { AuthService } from '../services/auth.service';
import { selectUserEmail } from '../../account/store/account.selectors';
import { openShortcutsModal } from '../store/app-common.actions';

@Component({
  selector: 'help-menu',
  template: `
    <div class="help dropup" [ngClass]="{ open: isOpen }">
      <a class="noselect dropdown-toggle" [matMenuTriggerFor]="dropdown">{{ 'sidebar.help' | translate }}</a>
      <mat-menu #dropdown="matMenu" yPosition="above" class="help-menu">
        <li mat-menu-item *ngIf="isAuthenticated()">
          <a
            href="http://xplenty.com/docs/"
            data-track-event="click"
            data-category="Help"
            data-action="Contextual Help"
            [attr.data-label]="'help-menu.labels.documentation' | translate"
            class="new-window"
            target="_blank"
          >
            <i class="icon fa fa-question-circle"></i>
            <span>{{ 'help-menu.labels.documentation' | translate }}</span></a
          >
        </li>
        <li mat-menu-item *ngIf="isAuthenticated()">
          <a
            href="https://www.integrate.io/docs/etl/integrateio-best-practices-guide/"
            data-track-event="click"
            data-category="Help"
            data-action="Contextual Help"
            [attr.data-label]="'help-menu.labels.power_user' | translate"
            class="new-window"
            target="_blank"
          >
            <i class="icon fa fa-question-circle"></i>
            <span>{{ 'help-menu.labels.power_user' | translate }}</span></a
          >
        </li>
        <li mat-menu-item *ngIf="isAuthenticated() && !isLaunchpad">
          <a
            (click)="openConsole()"
            data-track-event="click"
            data-category="Help"
            data-action="Contextual Help"
            [attr.data-label]="'help-menu.labels.console' | translate"
            class="new-window"
            target="_blank"
          >
            <i class="icon fa fa-code"></i>
            <span>{{ 'help-menu.labels.console' | translate }}</span></a
          >
        </li>
        <li mat-menu-item [ngClass]="helpOptions.length ? 'bottom-separator' : ''">
          <a
            [href]="'https://app.xplenty.com/help/schedule?email=' + (userEmail$ | async)"
            data-track-event="click"
            data-category="Help"
            data-action="Contextual Help"
            [attr.data-label]="'help-menu.labels.schedule_setup_session' | translate"
            class="new-window"
            target="_blank"
          >
            <i class="icon fa fa-calendar-check-o"></i>
            <span>{{ 'help-menu.labels.schedule_setup_session' | translate }}</span></a
          >
        </li>
        <li
          mat-menu-item
          *ngFor="let option of helpOptions"
          [hidden]="isGuide(option) || (!isVideo(option) && !isArticle(option))"
        >
          <a
            *ngIf="isVideo(option)"
            data-track-event="click"
            data-category="Help"
            data-action="Contextual Help"
            [attr.data-label]="option.title"
            [ngClass]="option.class"
            class="new-window"
            [href]="option.url"
            target="_blank"
          >
            <svg class="icon icon-video">
              <use href="#icon-video"></use></svg
            ><span>{{ option.title }}</span></a
          >
          <a
            *ngIf="isArticle(option)"
            [href]="option.url"
            data-track-event="click"
            data-category="Help"
            data-action="Contextual Help"
            [attr.data-label]="option.title"
            class="new-window"
            target="_blank"
          >
            <svg class="icon icon-book">
              <use href="#icon-book"></use></svg
            ><span>{{ option.title }}</span></a
          >
        </li>
        <li mat-menu-item class="top-separator">
          <a (click)="showKeyboardShortcuts()">
            <i class="icon fa fa-keyboard-o"></i>
            <span class="status-description">{{ 'help-menu.labels.keyboard_shortcuts' | translate }}</span></a
          >
        </li>
        <li mat-menu-item *ngIf="isAuthenticated()">
          <a href="http://status.xplenty.com" class="new-window service-status" target="_blank">
            <i class="icon fa fa-check-square"></i>
            <span class="status-description">{{ 'help-menu.labels.status_page' | translate }}</span></a
          >
        </li>
        <li mat-menu-item>
          <live-support-btn [message]="'help-menu.live_support_message' | translate"></live-support-btn>
        </li>
      </mat-menu>
    </div>
  `,
})
export class HelpMenuComponent implements OnInit {
  @Input() isOpen: boolean = false;
  helpOptions = [];
  userEmail$ = this.store.select(selectUserEmail);

  constructor(
    private store: Store<AppState>,
    private contextHelpService: ContextHelpService,
    private authService: AuthService,
    private router: Router,
    private route: ActivatedRoute,
  ) {}

  ngOnInit() {
    this.helpOptions = this.contextHelpService.getHelpForState(this.router.url);

    this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe((event: any) => {
      this.helpOptions = this.contextHelpService.getHelpForState(this.router.url);
    });
  }

  isAuthenticated(): boolean {
    return this.authService.isAuthenticated();
  }

  get isLaunchpad(): boolean {
    return this.router.url.includes('/launchpad');
  }

  openConsole() {
    const url = this.router.serializeUrl(
      this.router.createUrlTree([`/${this.route.snapshot.params.account_id}/console`]),
    );

    window.open(url, '_blank');
  }

  // eslint-disable-next-line class-methods-use-this
  isVideo(option): boolean {
    return option.type === 'video';
  }

  // eslint-disable-next-line class-methods-use-this
  isArticle(option): boolean {
    return option.type === 'article';
  }

  // eslint-disable-next-line class-methods-use-this
  isGuide(option): boolean {
    return option.type === 'guide';
  }

  showKeyboardShortcuts() {
    this.store.dispatch(openShortcutsModal());
  }
}
