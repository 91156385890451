import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Store } from '@ngrx/store';
import { AnyConnection } from '../../../../connections/connection.models';
import { connectionIconUrlByType } from '../../../helper/connection-icon-url-by-type.helper';
import { getConnectionItem } from '../../../../connections/store/connections.actions';
import { AppState } from '../../../../store';

@Component({
  selector: 'xp-select-picker-connection',
  template: `
    <div class="select-picker-connection">
      <div class="select-picker-item">
        <div class="select-picker-header">
          <div class="select-picker-icon">
            <img [src]="getConnectionIcon(connection.type)" [alt]="connection.name" />
          </div>
          <div class="select-picker-name" [innerHTML]="connection.name | xpHighLight: highlightValue"></div>
          <div class="select-picker-buttons">
            <button
              class="btn btn-sm btn-black"
              (click)="editConnectionItem($event, connection)"
              *ngxPermissionsOnly="'updateConnection'"
            >
              Edit
            </button>
          </div>
        </div>
        <div class="select-picker-body">
          <span class="small"
            >ID: {{ connection.unique_id }}
            @if (connection.status === 'archived' && connection.type.includes('salesforce')) {
              <span
                class="archived-connection-status"
                matTooltip="Refresh token expired, please reauthenticate"
                matTooltipPosition="above"
                matTooltipClass="above"
                ><i class="fa fa-exclamation"></i>(action required)</span
              >
            }
          </span>
          <span class="small" *ngIf="connection.owner">Owner: {{ connection.owner.display_name }}</span>
          <span class="small"
            >Last modified:
            <span class="date-tooltip" [matTooltip]="connection.updated_at | xpDateUTC">{{
              connection.updated_at | xpPrettyDate
            }}</span></span
          >
        </div>
      </div>
    </div>
  `,
})
export class XpSelectPickerConnectionComponent {
  @Input() connection: AnyConnection;
  @Input() highlightValue: string = '';
  @Output() editConnection = new EventEmitter<any>();

  constructor(private store: Store<AppState>) {}

  // eslint-disable-next-line class-methods-use-this
  getConnectionIcon = connectionIconUrlByType;

  editConnectionItem(event: MouseEvent, connection: AnyConnection) {
    event.stopPropagation();
    this.store.dispatch(getConnectionItem({ connectionId: connection.id, connectionType: connection.type }));
    this.editConnection.emit(connection);
  }
}
