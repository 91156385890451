import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { Observable, combineLatest } from 'rxjs';
import { SearchConfig } from './search-config.model';
import { AppState } from '../../../store';
import { Package } from '../../../packages/package.models';
import { getPackagesList } from '../../../packages/store/packages.actions';
import { selectPackages } from '../../../packages/store/packages.selectors';
import { getSchedulesList } from '../../../schedules/store/schedules.actions';
import { getMembersList } from '../../../settings/store/members/members.actions';
import { getClustersList } from '../../../clusters/store/clusters.actions';
import { selectSchedules } from '../../../schedules/store/schedules.selectors';
import { selectMembers } from '../../../settings/store/members/members.selectors';
import { selectClusters } from '../../../clusters/store/clusters.selectors';
import { Member } from '../../../settings/members.models';
import { Schedule } from '../../../schedules/schedule.models';
import { Cluster } from '../../../clusters/clusters.models';

const basicConfig: SearchConfig = {
  'id:': {
    type: 'default',
    defaultHint: 'Jobs with this ID',
  },
  'status:': {
    defaultHint: 'Jobs in status',
    type: 'list',
    sectionTitle: 'Status',
    content: [
      { value: 'pending', label: 'Pending' },
      { value: 'queued', label: 'Queued' },
      { value: 'stopped', label: 'Stopped' },
      { value: 'failed', label: 'Failed' },
      { value: 'completed', label: 'Completed' },
      { value: 'running', label: 'Running' },
      { value: 'stopping', label: 'Stopping' },
    ],
  },
  'started:>': {
    type: 'date',
    defaultHint: 'Jobs started after the date',
  },
  'started:<': {
    type: 'date',
    defaultHint: 'Jobs started before the date',
  },
  'finished:>': {
    type: 'date',
    defaultHint: 'Jobs finished after the date',
  },
  'finished:<': {
    type: 'date',
    defaultHint: 'Jobs finished before the date',
  },
  'duration:>': {
    defaultHint: 'Jobs run for greater seconds than',
  },
  'duration:<': {
    defaultHint: 'Jobs run for less seconds than',
  },
  'log:': {
    type: 'default',
    defaultHint: 'Jobs with this error log',
  },
  'package:': {
    type: 'list',
    defaultHint: 'Jobs run matching packages',
    sectionTitle: 'Packages',
    content: [], // list-compiliant autocomplete items will go here
  },
  'schedule:': {
    type: 'list',
    defaultHint: 'Jobs run by matching schedules',
    sectionTitle: 'Schedules',
    content: [], // list -compiliant autocomplete items will go here
  },
  'user:': {
    type: 'thumbs-list',
    defaultHint: 'Jobs run by matching users',
    sectionTitle: 'Users',
    content: [], // thumbs-list -compiliant autocomplete items will go here
  },
  'cluster:': {
    type: 'list',
    defaultHint: 'Jobs run on matching clusters',
    sectionTitle: 'Clusters',
    content: [], // list-compiliant autocomplete items will go here
  },
};

interface JobSearchItem {
  id: number;
  value: string;
  label: string;
  thumb_url?: string;
}

function prepareValue(stringVal: string): string {
  return stringVal.trim();
}

function formatItemForList(u: Package | Cluster | Schedule): JobSearchItem {
  return {
    id: u.id,
    value: prepareValue(u.name),
    label: u.name,
  };
}

function formatMemberForThumbsList(u: Member): JobSearchItem {
  return {
    id: u.id,
    value: prepareValue(u.name || u.email),
    label: u.name || u.email,
    thumb_url: u.avatar_url,
  };
}

@Injectable({
  providedIn: 'root',
})
export class AssistedSearchJobsConfiguratorService {
  constructor(private store: Store<AppState>) {}

  getConfig$(): Observable<SearchConfig> {
    this.store.dispatch(getPackagesList({ params: {} }));
    this.store.dispatch(getSchedulesList({ params: {} }));
    this.store.dispatch(getMembersList({ params: {} }));
    this.store.dispatch(getClustersList({ params: {} }));

    return combineLatest([
      this.store.select(selectPackages),
      this.store.select(selectSchedules),
      this.store.select(selectMembers),
      this.store.select(selectClusters),
    ]).pipe(
      map(([packages, schedules, members, clusters]) => {
        const configCopy = { ...basicConfig };
        configCopy['package:'].content = packages.map(formatItemForList);
        configCopy['schedule:'].content = schedules.map(formatItemForList);
        configCopy['user:'].content = members.map(formatMemberForThumbsList);
        configCopy['cluster:'].content = clusters.map(formatItemForList);
        return configCopy;
      }),
    );
  }
}
