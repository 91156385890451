import {
  AllComponentData,
  ColumnMap,
  Component,
  ConnectionSchemaField,
  Edge,
  Field,
  LambdaTransformationComponent,
  LambdaTransformationComponentData,
} from '../package.models';
import { getComponentId, getComponentType, getDataFromComponent } from './components.helpers';
import { COMPONENT_TYPE, isDestinationComponentType, isSourceComponentType } from '../../constants/component_type';
import { getComponentParentsSchema } from './component-schema.helpers';

function generateField(field: Field & ConnectionSchemaField): Field & ConnectionSchemaField {
  return Object.keys(field)
    .filter((key) => !['id', 'category'].includes(key))
    .reduce((acc, key) => ({ ...acc, [key]: field[key] }), {} as Field);
}

const ACCESS_MODE_COMPONENTS = [
  COMPONENT_TYPE.DATABASE_SOURCE_COMPONENT,
  COMPONENT_TYPE.SPANNER_SOURCE_COMPONENT,
  COMPONENT_TYPE.NETSUITE_SOURCE_COMPONENT,
  COMPONENT_TYPE.SALESFORCE_SOURCE_COMPONENT,
  COMPONENT_TYPE.BIG_QUERY_SOURCE_COMPONENT,
  COMPONENT_TYPE.AMAZON_REDSHIFT_SOURCE_COMPONENT,
];

export function modifyComponentBeforeSave(
  component: AllComponentData,
  componentType: keyof Component,
): AllComponentData {
  const newComponent: AllComponentData = {
    ...component,
    connection: {
      id: component.connection?.id,
      name: component.connection?.name,
      type: component.connection?.type,
    },
    alias: component.name,
  };

  if (newComponent.schema) {
    newComponent.schema = {
      ...component.schema,
    };

    if (newComponent.schema.fields) {
      newComponent.schema.fields = (newComponent.schema.fields || []).map(generateField);
    }
  }

  if (newComponent.column_mappings) {
    newComponent.column_mappings = newComponent.column_mappings.map((item) => {
      const field: ColumnMap = {
        ...item,
      };

      if (item.objectField) {
        const key =
          item.objectField.relationKeys.find((keyItem) => keyItem.name === item.relationship_field_name) || {};
        field.relationship_name = item.objectField.relationship_name;
        field.relationship_field_type = key.type;
        field.is_reference_key = key.is_reference_key;
        field.relationship_field_name = item.relationship_field_name;
      }

      return {
        column_name: field.column_name,
        field_name: field.field_name,
        field_type: field.field_type,
        is_reference_key: field.is_reference_key,
        relationship_field_name: field.relationship_field_name ? field.relationship_field_name : undefined,
        relationship_field_type: field.relationship_field_type ? field.relationship_field_type : undefined,
        relationship_name: field.relationship_name ? field.relationship_name : undefined,
        is_merge_key: field.is_merge_key ? field.is_merge_key : undefined,
        is_bson: field.is_bson ? field.is_bson : undefined,
        is_bag: field.is_bag ? field.is_bag : undefined,
      } as ColumnMap;
    });
  }

  ['fields', 'partitioned_fields', 'ordered_fields', 'windowed_fields', 'grouped_fields', 'aggregated_fields'].forEach(
    (attributeName) => {
      if (newComponent[attributeName]) {
        newComponent[attributeName] = newComponent[attributeName].map(generateField);
      }
    },
  );

  if (componentType === COMPONENT_TYPE.ADWORDS_SOURCE_COMPONENT) {
    newComponent.schema.fields = newComponent.schema.fields.filter((field) => field.name !== 'customer_id');
  }

  if (componentType === COMPONENT_TYPE.BIG_QUERY_SOURCE_COMPONENT) {
    if (newComponent.access_mode === 'query') {
      delete newComponent.table_name;
    }
  }

  if (componentType === COMPONENT_TYPE.CLOUD_STORAGE_DESTINATION_COMPONENT) {
    const textQualifier = newComponent.destination_type?.csv_destination_type?.text_qualifier;
    if (textQualifier === 'none_with_line_ending' || textQualifier === '') {
      newComponent.destination_type = {
        csv_destination_type: {
          ...newComponent.destination_type.csv_destination_type,
          escape_character: null,
        },
      };
    }
  }

  if (componentType === COMPONENT_TYPE.CLOUD_STORAGE_SOURCE_COMPONENT) {
    if (newComponent.before_action === 'copy_manifest') {
      if (newComponent.manifest_path) {
        newComponent.before_action = 'copy';
      } else {
        newComponent.before_action = 'none';
        newComponent.manifest_connection = {};
        newComponent.manifest_path = '';
      }
    } else if (newComponent.before_action === 'copy') {
      newComponent.manifest_connection = {};
      newComponent.manifest_path = '';
    }

    const pathField = newComponent.schema.fields.find((field) => field.name === 'file_path');

    if (pathField) {
      newComponent.source_path_field_alias = pathField.alias;
      // when saving actual taken fields (for no apparent reason) are from _selectedFields
      newComponent.schema.fields = newComponent.schema.fields.filter((field) => field.name !== 'file_path');
    } else {
      newComponent.source_path_field_alias = '';
    }
  }

  if (componentType === COMPONENT_TYPE.ANALYTICS_SOURCE_COMPONENT) {
    newComponent.account_name_field_alias = newComponent.schema.fields.find(
      (field) => field.name === 'account_name',
    ).alias;
    newComponent.property_name_field_alias = newComponent.schema.fields.find(
      (field) => field.name === 'property_name',
    ).alias;
    newComponent.profile_name_field_alias = newComponent.schema.fields.find(
      (field) => field.name === 'profile_name',
    ).alias;
    newComponent.profile_id_field_alias = newComponent.schema.fields.find((field) => field.name === 'profile_id').alias;

    newComponent.schema.fields = newComponent.schema.fields.filter(
      (field) =>
        ![
          newComponent.account_name_field_alias,
          newComponent.property_name_field_alias,
          newComponent.profile_name_field_alias,
          newComponent.profile_id_field_alias,
        ].includes(field.alias),
    );
  }

  if (componentType === COMPONENT_TYPE.ANALYTICS_GA4_SOURCE_COMPONENT) {
    newComponent.account_name_field_alias = newComponent.schema.fields.find(
      (field) => field.name === 'account_name',
    ).alias;
    newComponent.property_name_field_alias = newComponent.schema.fields.find(
      (field) => field.name === 'property_name',
    ).alias;
    newComponent.property_id_field_alias = newComponent.schema.fields.find(
      (field) => field.name === 'property_id',
    ).alias;
    newComponent.account_id_field_alias = newComponent.schema.fields.find((field) => field.name === 'account_id').alias;

    newComponent.schema.fields = newComponent.schema.fields.filter(
      (field) =>
        ![
          newComponent.account_name_field_alias,
          newComponent.property_name_field_alias,
          newComponent.property_id_field_alias,
          newComponent.account_id_field_alias,
        ].includes(field.alias),
    );
  }

  if (componentType === COMPONENT_TYPE.BING_ADS_SOURCE_COMPONENT) {
    const dateFieldIndex = newComponent.schema.fields.findIndex((field) => field.name === 'time_period_date');

    if (dateFieldIndex > -1) {
      newComponent.time_period_date_field_alias = newComponent.schema.fields[dateFieldIndex].alias;
      newComponent.schema.fields = [...newComponent.schema.fields];
      newComponent.schema.fields.splice(dateFieldIndex, 1);
    }

    const hourFieldIndex = newComponent.schema.fields.findIndex((field) => field.name === 'time_period_hour');

    if (hourFieldIndex > -1) {
      newComponent.time_period_hour_field_alias = newComponent.schema.fields[hourFieldIndex].alias;
      newComponent.schema.fields = [...newComponent.schema.fields];
      newComponent.schema.fields.splice(hourFieldIndex, 1);
    }
  }

  if (!newComponent.schema && (isSourceComponentType(componentType) || isDestinationComponentType(componentType))) {
    newComponent.schema = {
      fields: [],
      valid: true,
    };
  }

  if (componentType === COMPONENT_TYPE.SALESFORCE_SOAP_DESTINATION_COMPONENT) {
    if (newComponent.column_mappings) {
      newComponent.column_mappings = newComponent.column_mappings.map((item) => {
        if (item.field_type === 'REFERENCE') {
          return {
            column_name: item.column_name,
            field_name: item.field_name,
            field_type: item.relationship_field_type,
          };
        }

        return item;
      });
    }
  }

  if (ACCESS_MODE_COMPONENTS.includes(componentType)) {
    if (newComponent.access_mode === 'query') {
      newComponent.schema_name = undefined;
      newComponent.table_name = undefined;
      newComponent.where_clause = undefined;
      newComponent.split_by_column_name = undefined;
      newComponent.parallel = undefined;
      newComponent.object_name = undefined;
      newComponent.source_action = undefined;
      newComponent.null_string = undefined;
    }
    if (newComponent.access_mode === 'table') {
      delete newComponent.query;
    }
  }

  if (componentType === COMPONENT_TYPE.LAMBDA_TRANSFORMATION_COMPONENT) {
    (newComponent as LambdaTransformationComponentData).fields = newComponent.fields.map((field) => ({
      ...field,
      name: field.name.replace('__id_field', 'id'),
    }));
  }

  if (newComponent.json_consts) {
    newComponent.json_consts = newComponent.json_consts.map((item) => ({
      ...item,
      column_name: item.column_name.replaceAll('"', '"').replaceAll("'", '"'),
    }));
  }

  return newComponent;
}

export function updateComponentInPackageComponents(components: Component[], component: AllComponentData): Component[] {
  return components.map((item, index) => {
    if (getComponentId(item) === component.id) {
      const componentType = getComponentType(components[index]);
      let newComponent = modifyComponentBeforeSave(component, componentType);
      newComponent = {
        ...getDataFromComponent(item),
        ...newComponent,
      };

      if (componentType === COMPONENT_TYPE.SALESFORCE_DESTINATION_COMPONENT) {
        if (!newComponent.track_errors) {
          delete newComponent.track_errors;
          delete newComponent.errors_output_connection;
          delete newComponent.errors_output_path;
        }
      }

      return {
        [componentType]: newComponent,
      } as any;
    }
    return item;
  });
}

export function updateComponentsInPackageComponents(
  components: Component[],
  componentsToUpdate: Partial<AllComponentData>[],
): Component[] {
  return components.map((item, index) => {
    const component = componentsToUpdate.find((com) => com.id === getComponentId(item));
    if (component) {
      return {
        [getComponentType(components[index])]: {
          ...getDataFromComponent(item),
          ...component,
        },
      } as any;
    }
    return item;
  });
}

export function updateComponentsOrder(edges: Edge[], components: Component[]): Edge[] {
  const newEdges = [...edges];
  components.forEach((component, index) => {
    const foundEdgeIndex = edges.findIndex((edge) => edge.source === getDataFromComponent(component).id);

    newEdges[foundEdgeIndex] = { ...edges[foundEdgeIndex], order: index };
  });

  return [...newEdges];
}

export function updateSchemaFromParent(component: Component, components: Component[], edges: Edge[]): AllComponentData {
  const componentData = getDataFromComponent(component);
  const parentSchemas = getComponentParentsSchema(component, components, edges);
  const newSchema = {
    ...componentData.schema,
    fields: ((parentSchemas[0] || {}).fields || []).map((field) => ({
      name: field.name,
      alias: field.name,
      data_type: 'string',
    })),
  };

  return {
    ...componentData,
    schema: newSchema,
  };
}
