import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild, Inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { map, Subscription } from 'rxjs';
import { NgForm } from '@angular/forms';
import { escape } from 'lodash';
import moment from 'moment';
import { Connection, ConnectionSchemaField, NetsuiteSoapSourceComponentData, Schema } from '../../package.models';
import { AppState } from '../../../store';
import { setComponentValidity, updateComponent, updateRawComponent } from '../../store/component.actions';
import { BaseForm, BaseFormInterface } from '../../../common/base/base-form.component';
import { NotifyService } from '../../../common/services/notify.service';
import { ComponentTypeItem } from '../../../constants/component_types';
import { getStep } from '../../../common/helper/get-step.helper';
import { Step } from '../../../common/components/xp-steps.component';
import { SelectPickerTypes } from '../../../common/components/forms/select-picker/select-picker-types.enum';
import { connectionIconUrlByType } from '../../../common/helper/connection-icon-url-by-type.helper';
import { ComponentFormTagsService } from '../../../common/services/component-form-tags.service';
import { getConnectionSchema, setConnectionSchemaLoadingFlag } from '../../../connections/store/connections.actions';
import { getDataForSchemaImporter } from '../../helpers/schema.helpers';
import { AuthorizationGuard } from '../../../common/services/authorization.guard';
import { NetsuiteResource, NetsuiteSchema } from '../../resources/netsuite.resource';
import { SelectOption } from '../../../common/components/forms/xp-select.component';
import { selectVariables } from '../../store/package-designer.selectors';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';

function isValidDate(date: any): boolean {
  return date instanceof Date && !Number.isNaN(date) && date.toString() !== 'Invalid Date';
}

@Component({
  selector: 'netsuite-soap-source-editor',
  template: `
    <div>
      <xp-steps>
        <xp-step [step]="connectionStep">
          <xp-select-picker-editable
            id="connection-picker-component"
            [type]="selectPickerTypes.connection"
            [value]="rawComponent.connection"
            placeholder="Select connection"
            emptyPlaceholder="Connections list is empty"
            (valueChange)="onSelectConnection($event)"
            (createNew)="onCreateNewConnection($event)"
            [params]="{ type: component.connectionTypes }"
            [connectionTypes]="component.connectionTypes.split(',')"
          ></xp-select-picker-editable>
        </xp-step>
        <xp-step [step]="componentBaseStep" (activate)="onBaseStepActivation()">
          <div class="netsuite-source-editor">
            <xp-form-validation type="Xplenty::JobAuthoring::Components::NetSuiteSourceComponent">
              <form name="componentForm" novalidate #form="ngForm">
                <div class="row">
                  <div class="col-md-6">
                    <div class="form-group">
                      <label for="schema_name">{{
                        'netsuite-soap-source-editor.form.labels.schema_name' | translate
                      }}</label>
                      <xp-select
                        name="schema_name"
                        id="schema_name"
                        [value]="rawComponent.schema_name"
                        [options]="schemaOptions"
                        [preventEmpty]="true"
                        class="form-control xp-select"
                        (valueChange)="onSchemaNameChange($event)"
                        emptyPlaceholder="No schema name found"
                      ></xp-select>
                    </div>
                    @if (isIncrementalSectionVisible) {
                      <div class="form-group">
                        <xp-input-checkbox
                          name="incremental_load"
                          id="incremental_load"
                          [ngModel]="isIncrementalLoad"
                          [labelText]="'Set incremental load'"
                          (ngModelChange)="onIncrementalLoadChange($event)"
                        ></xp-input-checkbox>
                      </div>
                      <div class="form-group" *ngIf="isIncrementalLoad">
                        <label for="incremental_load_field_name">{{
                          'netsuite-soap-source-editor.form.labels.incremental_load_field_name' | translate
                        }}</label>
                        <xp-select
                          name="incremental_load_field_name"
                          id="incremental_load_field_name"
                          [value]="rawComponent.incremental_load_field_name"
                          [options]="dateTimeFields"
                          class="form-control xp-select"
                          [preventEmpty]="true"
                          (valueChange)="onDateTimeFieldChange($event)"
                          emptyPlaceholder="No date time field found"
                        ></xp-select>
                      </div>
                      <div class="form-group" *ngIf="isIncrementalLoad">
                        <label for="incremental_load_condition">{{
                          'netsuite-soap-source-editor.form.labels.incremental_load_condition' | translate
                        }}</label>
                        <xp-select
                          name="incremental_load_condition"
                          id="incremental_load_condition"
                          [value]="rawComponent.incremental_load_condition"
                          [options]="incrementalLoadConditions"
                          [preventEmpty]="true"
                          (valueChange)="onIncrementalLoadConditionChange($event)"
                          class="form-control xp-select"
                        ></xp-select>
                      </div>
                      <div class="date-range netsuite-date" *ngIf="isIncrementalLoad">
                        <div class="date-range-buttons-container">
                          <label for="incremental_load_date">{{
                            'netsuite-soap-source-editor.form.labels.incremental_load_date' | translate
                          }}</label>
                          <div class="btn-group btn-group-md btn-group-select">
                            <button
                              type="button"
                              class="btn btn-default"
                              [ngClass]="{ 'active btn-primary': !isIncrementalLoadConditionAsVariable }"
                              (click)="isIncrementalLoadConditionAsVariable = false"
                            >
                              Date
                            </button>
                            <button
                              type="button"
                              class="btn btn-default"
                              [ngClass]="{ 'active btn-primary': isIncrementalLoadConditionAsVariable }"
                              (click)="isIncrementalLoadConditionAsVariable = true"
                            >
                              Variable
                            </button>
                          </div>

                          <mat-form-field
                            appearance="fill"
                            class="example-form-field"
                            *ngIf="!isIncrementalLoadConditionAsVariable"
                          >
                            <mat-label>Choose an incremental load date</mat-label>
                            <input
                              matInput
                              [matDatepicker]="incrementalLoadDatepicker"
                              [ngModel]="rawComponent.incremental_load_date"
                              name="incremental_load_date"
                              (dateChange)="onIncrementalLoadDateChange($event)"
                            />
                            <mat-datepicker-toggle matSuffix [for]="incrementalLoadDatepicker"></mat-datepicker-toggle>
                            <mat-datepicker #incrementalLoadDatepicker> </mat-datepicker>
                          </mat-form-field>
                          <div class="form-group" *ngIf="isIncrementalLoadConditionAsVariable">
                            <xp-select
                              [value]="rawComponent.incremental_load_date"
                              [options]="variablesOptions$ | async"
                              (valueChange)="onIncrementalLoadDateVariableChange($event)"
                              name="incremental_load_date"
                              id="incremental_load_date"
                              placeholder="Select a variable"
                              class="form-control xp-select"
                            ></xp-select>
                          </div>
                        </div>
                      </div>
                    }
                  </div>
                </div>
              </form>
            </xp-form-validation>
          </div>
        </xp-step>
        <xp-step [step]="schemaImporterStep" (activate)="activateSchemaStep()" [isWide]="true">
          <schema-importer
            (fieldsChange)="onFieldsChange($event)"
            [fields]="(rawComponent.schema || {}).fields || []"
            [component]="component"
            [rawComponent]="rawComponent"
            [errorMessage]="schemaImporterErrorMessage"
            (updateValidation)="updateSchemaValidation($event)"
          ></schema-importer>
        </xp-step>
      </xp-steps>
    </div>
  `,
})
export class NetsuiteSoapSourceEditorComponent extends BaseForm implements BaseFormInterface, OnChanges {
  @Input() rawComponent: NetsuiteSoapSourceComponentData;
  @Input() component: ComponentTypeItem;
  @Input() parentSchemas: Schema[];
  @Output() formValidationChange = new EventEmitter<boolean>();
  @Output() createConnection = new EventEmitter();
  @ViewChild('form') form: NgForm;
  formName = 'componentForm';
  successMessageText = '';
  isIncrementalLoad = false;

  schemaOptions: SelectOption[] = [];
  tables: NetsuiteSchema = {};
  dateTimeFields: SelectOption[] = [];
  incrementalLoadConditions: SelectOption[] = [
    { text: 'Before', value: 'before' },
    { text: 'After', value: 'after' },
  ];
  isIncrementalLoadConditionAsVariable = false;

  variablesOptions$ = this.store
    .select(selectVariables)
    .pipe(map((variables) => Object.keys(variables || {}).map((key) => ({ value: key, text: key }))));

  selectPickerTypes = SelectPickerTypes;
  schemaImporterErrorMessage = '';

  isSchemaValid = true;
  isFormValid = true;
  validationChangeSubscription: Subscription;
  isBaseComponentStepActivated = false;
  isIncrementalSectionVisible = true;

  connectionStep: Step = getStep({ active: true });
  componentBaseStep: Step = getStep({});
  schemaImporterStep: Step = getStep({});

  constructor(
    protected store: Store<AppState>,
    protected notify: NotifyService,
    protected translate: TranslateService,
    private componentFormTagsService: ComponentFormTagsService,
    private authGuard: AuthorizationGuard,
    private netsuiteResource: NetsuiteResource,
  ) {
    super();
  }

  ngOnInit() {
    super.ngOnInit();
    this.validationChangeSubscription = this.formValidationChange.subscribe((isFormValid) => {
      this.isFormValid = isFormValid;

      this.componentBaseStep = {
        ...this.componentBaseStep,
        valid: !!this.rawComponent.connection?.id && isFormValid,
        isError: this.isBaseComponentStepActivated && !isFormValid,
        tags: this.componentFormTagsService.getTags(this.rawComponent, this.component),
      };
      this.onValidityChange();
    });

    this.connectionStep = getStep({
      title: this.translate.instant(`component-editor.step-connection.${this.component.type}.closed`),
      activeTitle: this.translate.instant(`component-editor.step-connection.${this.component.type}.active`),
      valid: !!this.rawComponent.connection?.id,
      active: true,
    });

    this.componentBaseStep = getStep({
      title: this.translate.instant(`component-editor.step-editor.${this.component.componentType}.closed`),
      activeTitle: this.translate.instant(`component-editor.step-editor.${this.component.componentType}.active`),
      valid: !!this.rawComponent.connection?.id,
      tags: this.rawComponent.connection?.id
        ? this.componentFormTagsService.getTags(this.rawComponent, this.component)
        : [],
    });

    this.schemaImporterStep = getStep({
      title: this.translate.instant(`component-editor.step-schema.${this.component.componentType}.closed`),
      activeTitle: this.translate.instant(`component-editor.step-schema.${this.component.componentType}.active`),
      valid: !!this.rawComponent.schema?.fields?.length,
      tags: (((this.rawComponent.schema || {}).fields as ConnectionSchemaField[]) || []).map((field) => ({
        name: field.alias,
      })),
    });

    this.netsuiteResource.get().subscribe((res: NetsuiteSchema) => {
      this.tables = res;
      this.schemaOptions = Object.keys(res).map((entity) => {
        return { text: entity, value: entity };
      });

      this.onSchemaNameChange(this.rawComponent.schema_name || (this.schemaOptions[0].value as string));
    });

    if (this.rawComponent.incremental_load_field_name) {
      this.isIncrementalLoad = true;
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    this.componentBaseStep = {
      ...this.componentBaseStep,
      tags: this.rawComponent.connection?.id
        ? this.componentFormTagsService.getTags(this.rawComponent, this.component)
        : [],
    };

    if (
      changes.rawComponent?.currentValue?.incremental_load_date !==
      changes.rawComponent?.previousValue?.incremental_load_date
    ) {
      const isValidStartDate = isValidDate(new Date(this.rawComponent.incremental_load_date));
      if (isValidStartDate || !this.rawComponent.incremental_load_date) {
        this.rawComponent.incremental_load_date = this.rawComponent.incremental_load_date
          ? moment(this.rawComponent.incremental_load_date).toDate()
          : ('' as any);
        this.isIncrementalLoadConditionAsVariable = false;
      } else {
        this.isIncrementalLoadConditionAsVariable = true;
        this.rawComponent.incremental_load_date = (this.rawComponent.incremental_load_date as string).replace('$', '');
      }
    }
  }

  onIncrementalLoadChange(value: boolean) {
    this.isIncrementalLoad = value;
    if (!value) {
      this.store.dispatch(updateRawComponent({ rawComponent: { incremental_load_field_name: null } }));
      this.store.dispatch(updateRawComponent({ rawComponent: { incremental_load_condition: null } }));
      this.store.dispatch(updateRawComponent({ rawComponent: { incremental_load_date: null } }));
    } else {
      this.onSchemaNameChange(this.rawComponent.schema_name);
    }
  }

  onSchemaNameChange(schemaName: string) {
    this.store.dispatch(updateRawComponent({ rawComponent: { schema_name: schemaName } }));
    this.dateTimeFields = this.tables[schemaName].fields
      .filter((field) => field.type === 'dateTime')
      .map((field) => {
        return { text: field.name, value: field.name };
      });
    this.isIncrementalLoad = this.dateTimeFields.length > 0;
    if (this.rawComponent.schema_name !== schemaName) {
      if (this.dateTimeFields.length > 0) {
        this.onDateTimeFieldChange(this.dateTimeFields[0].value as string);
        this.onIncrementalLoadConditionChange(this.incrementalLoadConditions[0].value as string);
      }
    }

    this.isIncrementalSectionVisible = this.dateTimeFields.length > 0;

    if (!this.isIncrementalLoad) {
      this.onIncrementalLoadChange(false);
    }
  }

  onDateTimeFieldChange(dateTimeField: string) {
    this.store.dispatch(updateRawComponent({ rawComponent: { incremental_load_field_name: dateTimeField } }));
  }

  onIncrementalLoadConditionChange(condition: string) {
    this.store.dispatch(updateRawComponent({ rawComponent: { incremental_load_condition: condition } }));
  }

  onIncrementalLoadDateChange(date: MatDatepickerInputEvent<Date>) {
    const incrementalLoadDateValue = moment(date.value).format('ll');
    if (!date || this.rawComponent.incremental_load_date === incrementalLoadDateValue) {
      return;
    }

    this.store.dispatch(updateRawComponent({ rawComponent: { incremental_load_date: incrementalLoadDateValue } }));
  }

  onIncrementalLoadDateVariableChange(variable: string) {
    this.store.dispatch(updateRawComponent({ rawComponent: { incremental_load_date: variable } }));
  }

  onBaseStepActivation() {
    this.isBaseComponentStepActivated = true;
  }

  updateSchemaValidation(isSchemaValid: boolean) {
    this.isSchemaValid = isSchemaValid;

    this.schemaImporterStep = { ...this.schemaImporterStep, valid: this.isSchemaValid, isError: !this.isSchemaValid };
    this.onValidityChange();
  }

  activateSchemaStep() {
    if (this.rawComponent.connection?.id) {
      this.store.dispatch(setConnectionSchemaLoadingFlag({ value: true }));
      const schemaRequestData = getDataForSchemaImporter(
        this.rawComponent,
        this.component.componentType,
        this.authGuard.account.account_id,
      );

      this.store.dispatch(
        getConnectionSchema({
          connectionId: this.rawComponent.connection?.id,
          connectionType: this.rawComponent.connection?.type,
          schemaRequestData,
        }),
      );
    }
  }

  onValidityChange() {
    const isValid = this.isSchemaValid && this.isFormValid;

    this.store.dispatch(setComponentValidity({ isComponentFormValid: isValid }));
  }

  onSelectConnection(connection: Partial<Connection>) {
    this.store.dispatch(
      updateRawComponent({
        rawComponent: { connection },
      }),
    );
    this.store.dispatch(updateComponent({ component: { connection } }));

    const img = `<img class="tag-icon" src="${connectionIconUrlByType(connection.type)}" alt="${connection.name}" />`;

    this.connectionStep.tags = [
      {
        name: `${img}<b>${escape(connection.name)}</b>`,
      },
    ];

    this.connectionStep = { ...this.connectionStep, valid: true };
    this.componentBaseStep = { ...this.componentBaseStep, valid: this.isFormValid };
  }

  onCreateNewConnection(params) {
    this.createConnection.emit(params);
  }

  onValueChange(value: any, key: string) {
    this.store.dispatch(
      updateRawComponent({
        rawComponent: { [key]: value },
      }),
    );
    this.store.dispatch(updateComponent({ component: { [key]: value } }));
  }

  onFieldsChange(fields: ConnectionSchemaField[]) {
    this.schemaImporterStep = {
      ...this.schemaImporterStep,
      tags: fields.map((field) => ({
        name: field.alias,
      })),
    };

    this.store.dispatch(
      updateRawComponent({
        rawComponent: { schema: { ...this.rawComponent.schema, fields } },
      }),
    );
  }

  ngOnDestroy() {
    super.ngOnDestroy();

    if (this.validationChangeSubscription) {
      this.validationChangeSubscription.unsubscribe();
    }
  }
}
