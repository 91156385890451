import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import jwt_decode from 'jwt-decode';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { filter, first, tap, withLatestFrom } from 'rxjs/operators';
import { isEmpty } from 'lodash';
import { AnyConnection, ConnectionTypeName, SalesforceEnvironment } from '../connection.models';
import { connectionIconUrlByType } from '../../common/helper/connection-icon-url-by-type.helper';
import { AppState } from '../../store';
import {
  selectConnectionGroupTypes,
  selectConnectionItem,
  selectConnectionSelectedType,
  selectConnectionsErrors,
  selectConnectionsIsSubmittingFlag,
  selectConnectionsLoadingItemFlag,
  selectConnectionsTestingFlag,
  selectConnectionsTestingSuccessMessage,
} from '../store/connections.selectors';
import {
  closeConnectionsModal,
  saveConnection,
  selectConnectionType,
  testConnection,
  updateConnection,
} from '../store/connections.actions';
import { transformErrorFieldName } from '../../common/helper/transform-error-field-name.helper';
import { AuthService } from '../../common/services/auth.service';
import { ConnectionAuthenticationService } from '../services/connection-authentication.service';
import { OAuthName } from '../helpers/connections-authentication.config';
import { selectAccountIdNumber, selectGCSV2FeatureFlag } from '../../account/store/account.selectors';
import { DUMMY_PASSWORD } from './forms/connection-component-username-password.component';

const AUTHENTICATE_TYPES = [
  ConnectionTypeName.analytics,
  ConnectionTypeName.adwords,
  ConnectionTypeName.googleCloudSpanner,
  ConnectionTypeName.salesforce,
  ConnectionTypeName.bingAds,
  ConnectionTypeName.facebookAdsInsights,
  ConnectionTypeName.facebookAds,
  ConnectionTypeName.intercom,
  ConnectionTypeName.pipedrive,
  ConnectionTypeName.xero,
  ConnectionTypeName.linkedin,
  ConnectionTypeName.instagram,
  ConnectionTypeName.googleSheets,
  ConnectionTypeName.googleDrive,
  ConnectionTypeName.youtube,
  ConnectionTypeName.shopify,
  ConnectionTypeName.tiktokads,
  ConnectionTypeName.hubspotOAuth,
];

function getConnectionData(defaultValues, connectionValues) {
  const diff = {};
  // eslint-disable-next-line guard-for-in,no-restricted-syntax
  for (const key in connectionValues) {
    const isDummyPassword = key === 'password' && connectionValues[key] === DUMMY_PASSWORD;
    const isDummyTokenSecret = key === 'token_secret' && connectionValues[key] === DUMMY_PASSWORD;
    const isDummyConsumerSecret = key === 'consumer_secret' && connectionValues[key] === DUMMY_PASSWORD;
    const isDummySecurityToken = key === 'security_token' && connectionValues[key] === DUMMY_PASSWORD;
    const isDummyPassphrase = key === 'snowflake_private_key_password' && connectionValues[key] === DUMMY_PASSWORD;
    if (
      // eslint-disable-next-line no-prototype-builtins
      connectionValues.hasOwnProperty(key) &&
      // eslint-disable-next-line no-prototype-builtins
      (!defaultValues.hasOwnProperty(key) || connectionValues[key] !== defaultValues[key]) &&
      !isDummyPassword &&
      !isDummyTokenSecret &&
      !isDummyConsumerSecret &&
      !isDummySecurityToken &&
      !isDummyPassphrase
    ) {
      diff[key] = connectionValues[key];
    }
  }
  return diff;
}

@Component({
  selector: 'connection-form',
  template: `
    <div class="connection-form" data-id="{{ item.id }}">
      <div class="connection-form-body">
        <div class="connection-groups-list" *ngIf="!type">
          <div class="connections-group-item" *ngFor="let group of groups$ | async">
            <div class="title-container">
              <div class="components-list-item-icon-background"></div>
              <svg width="32" height="32" xmlns="http://www.w3.org/2000/svg">
                <use class="components-list-item-icon" width="32" height="32" [attr.href]="'#icon-' + group.type"></use>
              </svg>
              <strong class="lead">{{ group.name }}</strong>
            </div>
            <div
              class="connection-item connection-item-{{ item.type }}"
              *ngFor="let connection of group.connections"
              [hidden]="connection.type === connectionTypeNames.gsv2 && !(gcsV2Enabled$ | async)"
              (click)="selectConnectionType(connection.type)"
            >
              <img [src]="getConnectionIcon(connection.type)" [alt]="connection.type" />
              <span>{{ connection.name }}</span>
            </div>
          </div>
        </div>
        <xp-loader *ngIf="isLoading$ | async"></xp-loader>
        <div class="connection-form-wrapper" *ngIf="!!type && (connectionType$ | async) as connectionType">
          <div class="connection-type-header">
            <img class="connection-logo" [src]="getConnectionIcon(type)" [alt]="type" />
            <div class="connection-description">
              <strong>{{ connectionType.name }}</strong>
              <p>{{ connectionType.description }}</p>
            </div>
          </div>
          <div>
            <div [ngSwitch]="type" class="connection-form-container modal-form-container" *ngIf="!(isLoading$ | async)">
              <connection-form-postgres
                *ngSwitchCase="connectionTypeNames.postgres"
                [connection]="item"
                (formValidationChange)="onFormValidationChange($event)"
                (tunnelTypeChange)="onTunnelTypeChange($event)"
              ></connection-form-postgres>
              <connection-form-mysql
                *ngSwitchCase="connectionTypeNames.mysql"
                [connection]="item"
                (formValidationChange)="onFormValidationChange($event)"
                (tunnelTypeChange)="onTunnelTypeChange($event)"
              ></connection-form-mysql>
              <connection-form-oracle
                *ngSwitchCase="connectionTypeNames.oracle"
                [connection]="item"
                (formValidationChange)="onFormValidationChange($event)"
                (tunnelTypeChange)="onTunnelTypeChange($event)"
              ></connection-form-oracle>
              <connection-form-oracle-adw
                *ngSwitchCase="connectionTypeNames.oracleADW"
                [connection]="item"
                (formValidationChange)="onFormValidationChange($event)"
                (tunnelTypeChange)="onTunnelTypeChange($event)"
              ></connection-form-oracle-adw>
              <connection-form-sql-server
                *ngSwitchCase="connectionTypeNames.sqlserver"
                [connection]="item"
                (formValidationChange)="onFormValidationChange($event)"
                (tunnelTypeChange)="onTunnelTypeChange($event)"
              ></connection-form-sql-server>
              <connection-form-heroku-postgres
                *ngSwitchCase="connectionTypeNames.herokuPostgres"
                [connection]="item"
                (formValidationChange)="onFormValidationChange($event)"
                (tunnelTypeChange)="onTunnelTypeChange($event)"
              ></connection-form-heroku-postgres>
              <connection-form-google-cloud-postgres
                *ngSwitchCase="connectionTypeNames.googleCloudPostgres"
                [connection]="item"
                (formValidationChange)="onFormValidationChange($event)"
                (tunnelTypeChange)="onTunnelTypeChange($event)"
              ></connection-form-google-cloud-postgres>
              <connection-form-alloy
                *ngSwitchCase="connectionTypeNames.alloy"
                [connection]="item"
                (formValidationChange)="onFormValidationChange($event)"
                (tunnelTypeChange)="onTunnelTypeChange($event)"
              ></connection-form-alloy>
              <connection-form-google-cloud-sql
                *ngSwitchCase="connectionTypeNames.googleCloudSql"
                [connection]="item"
                (formValidationChange)="onFormValidationChange($event)"
                (tunnelTypeChange)="onTunnelTypeChange($event)"
              ></connection-form-google-cloud-sql>
              <connection-form-azure-synapse-analytics
                *ngSwitchCase="connectionTypeNames.azureSynapseAnalytics"
                [connection]="item"
                (formValidationChange)="onFormValidationChange($event)"
                (tunnelTypeChange)="onTunnelTypeChange($event)"
              ></connection-form-azure-synapse-analytics>
              <connection-form-vertica
                *ngSwitchCase="connectionTypeNames.vertica"
                [connection]="item"
                (formValidationChange)="onFormValidationChange($event)"
                (tunnelTypeChange)="onTunnelTypeChange($event)"
              ></connection-form-vertica>
              <connection-form-db2
                *ngSwitchCase="connectionTypeNames.db2"
                [connection]="item"
                (formValidationChange)="onFormValidationChange($event)"
                (tunnelTypeChange)="onTunnelTypeChange($event)"
              ></connection-form-db2>
              <connection-form-as400
                *ngSwitchCase="connectionTypeNames.as400"
                [connection]="item"
                (formValidationChange)="onFormValidationChange($event)"
                (tunnelTypeChange)="onTunnelTypeChange($event)"
              ></connection-form-as400>
              <connection-form-sftp
                *ngSwitchCase="connectionTypeNames.sftp"
                [connection]="item"
                (formValidationChange)="onFormValidationChange($event)"
                (tunnelTypeChange)="onTunnelTypeChange($event)"
              ></connection-form-sftp>
              <connection-form-hdfs
                *ngSwitchCase="connectionTypeNames.hdfs"
                [connection]="item"
                (formValidationChange)="onFormValidationChange($event)"
              ></connection-form-hdfs>
              <connection-form-ftps
                *ngSwitchCase="connectionTypeNames.ftps"
                [connection]="item"
                (formValidationChange)="onFormValidationChange($event)"
              ></connection-form-ftps>
              <connection-form-restapi *ngSwitchCase="connectionTypeNames.restapi"></connection-form-restapi>
              <connection-form-athena
                *ngSwitchCase="connectionTypeNames.athena"
                [connection]="item"
                (formValidationChange)="onFormValidationChange($event)"
              ></connection-form-athena>
              <connection-form-redshift
                *ngSwitchCase="connectionTypeNames.redshift"
                [connection]="item"
                (formValidationChange)="onFormValidationChange($event)"
                (tunnelTypeChange)="onTunnelTypeChange($event)"
              ></connection-form-redshift>
              <connection-form-big-query
                *ngSwitchCase="connectionTypeNames.bigQuery"
                [connection]="item"
                (formValidationChange)="onFormValidationChange($event)"
              ></connection-form-big-query>
              <connection-form-big-query-v2
                *ngSwitchCase="connectionTypeNames.bigQueryV2"
                [connection]="item"
                (formValidationChange)="onFormValidationChange($event)"
              ></connection-form-big-query-v2>
              <connection-form-snowflake
                *ngSwitchCase="connectionTypeNames.snowflake"
                [connection]="item"
                (formValidationChange)="onFormValidationChange($event)"
              ></connection-form-snowflake>
              <connection-form-s3
                *ngSwitchCase="connectionTypeNames.s3"
                [connection]="item"
                (formValidationChange)="onFormValidationChange($event)"
              ></connection-form-s3>
              <connection-form-azure-blob-storage
                *ngSwitchCase="connectionTypeNames.azureBlobStorage"
                [connection]="item"
                (formValidationChange)="onFormValidationChange($event)"
              ></connection-form-azure-blob-storage>
              <connection-form-gs
                *ngSwitchCase="connectionTypeNames.gs"
                [connection]="item"
                (formValidationChange)="onFormValidationChange($event)"
              ></connection-form-gs>
              <connection-form-gs2
                *ngSwitchCase="connectionTypeNames.gsv2"
                [connection]="item"
                (formValidationChange)="onFormValidationChange($event)"
              ></connection-form-gs2>
              <connection-form-mongo
                *ngSwitchCase="connectionTypeNames.mongo"
                [connection]="item"
                (formValidationChange)="onFormValidationChange($event)"
                (tunnelTypeChange)="onTunnelTypeChange($event)"
              ></connection-form-mongo>
              <connection-form-adwords
                *ngSwitchCase="connectionTypeNames.adwords"
                [connection]="item"
                [connectionTypeButtonCreate]="connectionTypeButton.create"
                (formValidationChange)="onFormValidationChange($event)"
              ></connection-form-adwords>
              <connection-form-analytics
                *ngSwitchCase="connectionTypeNames.analytics"
                [connection]="item"
                [connectionTypeButtonCreate]="connectionTypeButton.create"
                (formValidationChange)="onFormValidationChange($event)"
              ></connection-form-analytics>
              <connection-form-analytics-v4
                *ngSwitchCase="connectionTypeNames.analyticsV4"
                [connection]="item"
                (formValidationChange)="onFormValidationChange($event)"
              ></connection-form-analytics-v4>
              <connection-form-google-cloud-spanner
                *ngSwitchCase="connectionTypeNames.googleCloudSpanner"
                [connection]="item"
                [connectionTypeButtonCreate]="connectionTypeButton.create"
                (formValidationChange)="onFormValidationChange($event)"
              ></connection-form-google-cloud-spanner>
              <connection-form-google-sheets
                *ngSwitchCase="connectionTypeNames.googleSheets"
                [connection]="item"
                [connectionTypeButtonCreate]="connectionTypeButton.create"
                (formValidationChange)="onFormValidationChange($event)"
              ></connection-form-google-sheets>
              <connection-form-google-drive
                *ngSwitchCase="connectionTypeNames.googleDrive"
                [connection]="item"
                [connectionTypeButtonCreate]="connectionTypeButton.create"
                (formValidationChange)="onFormValidationChange($event)"
              ></connection-form-google-drive>
              <connection-form-youtube
                *ngSwitchCase="connectionTypeNames.youtube"
                [connection]="item"
                [connectionTypeButtonCreate]="connectionTypeButton.create"
                (formValidationChange)="onFormValidationChange($event)"
              ></connection-form-youtube>
              <connection-form-facebook-ads-insights
                *ngSwitchCase="connectionTypeNames.facebookAdsInsights"
                [connection]="item"
                [connectionTypeButtonCreate]="connectionTypeButton.create"
                (formValidationChange)="onFormValidationChange($event)"
              ></connection-form-facebook-ads-insights>
              <connection-form-bing-ads
                *ngSwitchCase="connectionTypeNames.bingAds"
                [connection]="item"
                [connectionTypeButtonCreate]="connectionTypeButton.create"
                (formValidationChange)="onFormValidationChange($event)"
              ></connection-form-bing-ads>
              <connection-form-netsuite
                *ngSwitchCase="connectionTypeNames.netsuite"
                [connection]="item"
                (formValidationChange)="onFormValidationChange($event)"
              ></connection-form-netsuite>
              <connection-form-nsuite
                *ngSwitchCase="connectionTypeNames.netsuitesoap"
                [connection]="item"
                (formValidationChange)="onFormValidationChange($event)"
              ></connection-form-nsuite>
              <connection-form-rackspace
                *ngSwitchCase="connectionTypeNames.rackspace"
                [connection]="item"
                (formValidationChange)="onFormValidationChange($event)"
              ></connection-form-rackspace>
              <connection-form-salesforce
                *ngSwitchCase="connectionTypeNames.salesforce"
                [connection]="item"
                [connectionTypeButtonCreate]="connectionTypeButton.create"
                (formValidationChange)="onFormValidationChange($event)"
              ></connection-form-salesforce>
              <connection-form-softlayer
                *ngSwitchCase="connectionTypeNames.softlayer"
                [connection]="item"
                (formValidationChange)="onFormValidationChange($event)"
              ></connection-form-softlayer>
              <connection-form-swift
                *ngSwitchCase="connectionTypeNames.swift"
                [connection]="item"
                (formValidationChange)="onFormValidationChange($event)"
              ></connection-form-swift>
              <connection-form-intercom
                *ngSwitchCase="connectionTypeNames.intercom"
                [connection]="item"
                [connectionTypeButtonCreate]="connectionTypeButton.create"
                (formValidationChange)="onFormValidationChange($event)"
              ></connection-form-intercom>
              <connection-form-pipedrive
                *ngSwitchCase="connectionTypeNames.pipedrive"
                [connection]="item"
                [connectionTypeButtonCreate]="connectionTypeButton.create"
                (formValidationChange)="onFormValidationChange($event)"
              ></connection-form-pipedrive>
              <connection-form-xero
                *ngSwitchCase="connectionTypeNames.xero"
                [connection]="item"
                [connectionTypeButtonCreate]="connectionTypeButton.create"
                (formValidationChange)="onFormValidationChange($event)"
              ></connection-form-xero>
              <connection-form-linkedin
                *ngSwitchCase="connectionTypeNames.linkedin"
                [connection]="item"
                [connectionTypeButtonCreate]="connectionTypeButton.create"
                (formValidationChange)="onFormValidationChange($event)"
              ></connection-form-linkedin>
              <connection-form-instagram
                *ngSwitchCase="connectionTypeNames.instagram"
                [connection]="item"
                [connectionTypeButtonCreate]="connectionTypeButton.create"
                (formValidationChange)="onFormValidationChange($event)"
              ></connection-form-instagram>
              <connection-form-shopify
                *ngSwitchCase="connectionTypeNames.shopify"
                [connection]="item"
                [connectionTypeButtonCreate]="connectionTypeButton.create"
                (formValidationChange)="onFormValidationChange($event)"
              ></connection-form-shopify>
              <connection-form-marketing-cloud
                *ngSwitchCase="connectionTypeNames.marketingCloud"
                [connection]="item"
                (formValidationChange)="onFormValidationChange($event)"
              ></connection-form-marketing-cloud>
              <connection-form-marketing-cloud-rest
                *ngSwitchCase="connectionTypeNames.marketingCloudRest"
                [connection]="item"
                (formValidationChange)="onFormValidationChange($event)"
              ></connection-form-marketing-cloud-rest>
              <connection-form-facebook-ads
                *ngSwitchCase="connectionTypeNames.facebookAds"
                [connection]="item"
                (formValidationChange)="onFormValidationChange($event)"
              ></connection-form-facebook-ads>
              <connection-form-salesforce-soap
                *ngSwitchCase="connectionTypeNames.salesforceSoap"
                [connection]="item"
                (formValidationChange)="onFormValidationChange($event)"
              ></connection-form-salesforce-soap>
              <connection-form-tiktok-ads
                *ngSwitchCase="connectionTypeNames.tiktokads"
                [connection]="item"
                [connectionTypeButtonCreate]="connectionTypeButton.create"
                (formValidationChange)="onFormValidationChange($event)"
              ></connection-form-tiktok-ads>
              <connection-form-hubspot
                *ngSwitchCase="connectionTypeNames.hubspot"
                [connection]="item"
                [connectionTypeButtonCreate]="connectionTypeButton.create"
                (formValidationChange)="onFormValidationChange($event)"
              ></connection-form-hubspot>
              <connection-form-hubspot-oauth
                *ngSwitchCase="connectionTypeNames.hubspotOAuth"
                [connection]="item"
                [connectionTypeButtonCreate]="connectionTypeButton.create"
                (formValidationChange)="onFormValidationChange($event)"
              ></connection-form-hubspot-oauth>
              <connection-form-salesforce-own-oauth
                *ngSwitchCase="connectionTypeNames.salesforceOwnOauth"
                [connection]="item"
                [connectionTypeButtonCreate]="connectionTypeButton.create"
                (formValidationChange)="onFormValidationChange($event)"
              ></connection-form-salesforce-own-oauth>
              <connection-form-twitter
                *ngSwitchCase="connectionTypeNames.twitter"
                [connection]="item"
                (formValidationChange)="onFormValidationChange($event)"
              ></connection-form-twitter>
            </div>
          </div>
        </div>
        <errors-notify [errors]="errorTexts"></errors-notify>
        <success-notify [success]="successTexts"></success-notify>
      </div>
      <div class="connection-form-footer modal-footer modal-panel-footer" *ngIf="type && isFooterShown">
        <div class="modal-title-container active">
          <common-icon iconId="icon-connection" size="L"></common-icon>

          <h3 class="modal-title">{{ title }}</h3>
        </div>
        <div class="buttons-container">
          <button
            id="connection-button-cancel"
            class="btn btn-lg btn-default pull-right"
            (click)="cancel()"
            *ngIf="isInModal"
          >
            {{ 'connections.form.default.buttons.cancel' | translate }}
          </button>
          <button
            id="connection-button-test-connection"
            data-action="submit"
            [ngClass]="{ loading: testLoading$ | async }"
            class="btn btn-lg btn-default pull-right"
            (click)="testConnection()"
            [disabled]="(testLoading$ | async) || flags.isTestDisabled"
            *ngIf="connectionTypeButton.test"
          >
            {{ 'connections.form.default.buttons.test' | translate }}
          </button>
          <button
            *ngIf="connectionTypeButton.authenticate"
            type="button"
            class="btn btn-lg"
            [ngClass]="{ 'btn-success': !connectionTypeButton.create, 'btn-default': connectionTypeButton.create }"
            (click)="authenticate()"
            [disabled]="isAuthenticateButtonDisabled"
          >
            <span *ngIf="!connectionTypeButton.create">{{
              'connection-component-authenticate-button.authenticate' | translate
            }}</span>
            <span *ngIf="connectionTypeButton.create">{{
              'connection-component-authenticate-button.reconnect' | translate
            }}</span>
          </button>

          <xp-submit-button
            (click)="saveConnection()"
            classNames="btn-lg btn-success pull-right"
            [createText]="!item.id && 'connections.form.default.buttons.create' | translate"
            [updateText]="item.id && 'connections.form.default.buttons.update' | translate"
            [isFormValid]="isFormValid"
            *ngIf="connectionTypeButton.create"
            [isFormSubmitting]="isSubmitting$ | async"
          ></xp-submit-button>
        </div>
      </div>
    </div>
  `,
})
export class ConnectionFormComponent implements OnInit, OnDestroy {
  @Input() item: Partial<AnyConnection>;
  @Input() isInModal = false;
  @Input() groupId: number;
  @Output() connectionCreated = new EventEmitter<Partial<AnyConnection>>();

  previousConnection: Partial<AnyConnection>;
  errors$ = this.store.select(selectConnectionsErrors);
  isSubmitting$ = this.store.select(selectConnectionsIsSubmittingFlag);
  connectionTypeNames = ConnectionTypeName;
  getConnectionIcon = connectionIconUrlByType;
  connectionType$ = this.store.select(selectConnectionSelectedType);
  testLoading$ = this.store.select(selectConnectionsTestingFlag);
  isLoading$ = this.store.select(selectConnectionsLoadingItemFlag);
  testSuccessMessage$ = this.store.select(selectConnectionsTestingSuccessMessage);
  connectionItem$ = this.store.select(selectConnectionItem);
  type: ConnectionTypeName = null;
  isFooterShown = true;
  flags = {
    isTestDisabled: false,
    isSaveDisabled: false,
    test: false,
  };
  groups$ = this.store.select(selectConnectionGroupTypes);
  gcsV2Enabled$ = this.store.select(selectGCSV2FeatureFlag);
  connectionTypeButton = {
    authenticate: false,
    create: false,
    test: false,
  };
  isFormValid = false;
  isConnectionSaved = false;
  defaultConnection: Partial<AnyConnection> = {};
  errorTexts = [];
  successTexts = [];
  errorsSubscriber: Subscription;
  successSubscriber: Subscription;
  connectionItemSubscriber: Subscription;
  connectionNewItemSubscriber: Subscription;
  title = 'Create new connection';

  constructor(
    private store: Store<AppState>,
    private authService: AuthService,
    private connectionAuthenticationService: ConnectionAuthenticationService,
    private router: Router,
    private route: ActivatedRoute,
  ) {}

  ngAfterViewInit() {
    if (this.router.url.match(/\/connections\/\w+\/new/) || this.router.url.match(/\/connections\/\w+\/edit/)) {
      this.selectConnectionType(this.route.firstChild?.snapshot.params.type);
    }
  }

  ngOnInit() {
    if (this.item && (this.item.id || this.item.type)) {
      this.selectConnectionType(this.route.firstChild?.snapshot.params.type || this.item.type);
      this.item.password = '';
      this.item.passwordDisabled = true;
    }

    this.errorsSubscriber = this.errors$.subscribe((errors) => {
      if (errors && errors.length) {
        this.errorTexts = errors.map(
          ({ field, message }) => `<strong>${transformErrorFieldName(field)}</strong> ${message}`,
        );
        setTimeout(() => {
          this.errorTexts = [];
        }, 7000);
      }
    });

    this.successSubscriber = this.testSuccessMessage$.subscribe((messages) => {
      if (messages.length) {
        this.successTexts = messages;
        setTimeout(() => {
          this.successTexts = [];
        }, 3000);
      }
    });

    this.connectionItemSubscriber = this.connectionItem$
      .pipe(filter((item) => Boolean(item) && !isEmpty(item)))
      .subscribe((connectionItem: AnyConnection) => {
        if (!this.isConnectionSaved || connectionItem.public_key !== this.item.public_key) {
          this.item = { ...connectionItem, password: this.item.password };
          this.initConnection();
          this.isConnectionSaved = false;
        }
      });
  }

  initConnection() {
    if (this.item && !!this.item.tunnel_type) {
      this.flags.isTestDisabled = this.item.tunnel_type === 'ssh-tunnel' && !this.item.public_key;
    }

    this.previousConnection = { ...this.item };
    this.item.saved = this.item.tunnel_type === 'reverse' || this.item.tunnel_type === 'ssh-tunnel';
    this.defaultConnection = { ...this.item };
    this.selectConnectionType(this.item.type);
  }

  onTunnelTypeChange(value: string) {
    this.flags.isTestDisabled = value === 'ssh-tunnel' && !this.item.public_key;

    if (this.previousConnection) {
      this.item.saved = value === this.previousConnection.tunnel_type;
    }
  }

  get isAuthenticateButtonDisabled() {
    if (this.item && this.item.auth_method === 'shopify') {
      return !this.item.shop || this.item.shop.length < 3;
    }

    return false;
  }

  cancel() {
    this.store.dispatch(closeConnectionsModal());
  }

  onFormValidationChange(isValid: boolean) {
    this.isFormValid = isValid;
    if (
      !AUTHENTICATE_TYPES.includes(this.type) &&
      this.type !== ConnectionTypeName.marketingCloud &&
      this.type !== ConnectionTypeName.marketingCloudRest
    ) {
      this.connectionTypeButton.test = isValid;
    }
  }

  selectConnectionType(type: ConnectionTypeName) {
    this.type = type;
    this.store.dispatch(selectConnectionType({ selectedType: type }));

    if (!this.item.id) {
      this.connectionType$
        .pipe(
          tap(({ short_name, name }) => {
            if (this.item && this.item.id) {
              this.title = `Edit ${short_name || name} connection`;
            } else {
              this.title = `Create new ${short_name || name} connection`;
            }
          }),
          filter(({ defaults }) => !!Object.keys(defaults || {}).length),
          first(),
        )
        .subscribe(({ defaults, name }) => {
          this.item = { ...defaults } as any;
        });
    } else {
      this.connectionType$.pipe(first()).subscribe(({ short_name, name }) => {
        this.title = `Edit ${short_name || name} connection`;
      });
    }

    this.initType(this.type);

    if (!this.item.id && !this.isInModal) {
      if (this.groupId) {
        this.router.navigate([`${this.groupId}/${type}/new`], { relativeTo: this.route });
      } else {
        this.router.navigate([`${type}/new`], { relativeTo: this.route });
      }
    }
  }

  initType(type: ConnectionTypeName) {
    if (!type) return;

    if (AUTHENTICATE_TYPES.includes(type)) {
      this.connectionTypeButton.authenticate = true;
      this.connectionTypeButton.create =
        (!!this.item && !!this.item.id) || (!!this.item && (!!this.item.code || !!this.item.jwt_token));
    } else if (type === ConnectionTypeName.restapi) {
      this.isFooterShown = false;
    } else {
      this.connectionTypeButton.create = true;
    }

    if (type === ConnectionTypeName.marketingCloud) {
      this.connectionTypeButton.test = false;
    }
  }

  saveConnection() {
    if (
      this.item.auth_method &&
      this.item.auth_method !== 'password' &&
      this.item.auth_method !== 'key' &&
      !(this.item.code || this.item.jwt_token)
    )
      return;

    if (this.item.id) {
      this.store.dispatch(
        updateConnection({
          connection: {
            ...this.item,
            password: this.item.password === DUMMY_PASSWORD || !this.item.password ? undefined : this.item.password,
            consumer_secret:
              this.item.consumer_secret === DUMMY_PASSWORD || !this.item.consumer_secret
                ? undefined
                : this.item.consumer_secret,
            token_secret:
              this.item.token_secret === DUMMY_PASSWORD || !this.item.token_secret ? undefined : this.item.token_secret,
            security_token:
              this.item.security_token === DUMMY_PASSWORD || !this.item.security_token
                ? undefined
                : this.item.security_token,
            snowflake_private_key_password:
              this.item.snowflake_private_key_password === DUMMY_PASSWORD ||
              (!this.item.snowflake_private_key_password && this.item.snowflake_private_key_password !== '')
                ? undefined
                : this.item.snowflake_private_key_password,
            client_secret:
              this.item.client_secret === DUMMY_PASSWORD || !this.item.client_secret
                ? undefined
                : this.item.client_secret,
          },
          connectionType: this.type,
          connectionId: this.item.id,
          params: {},
        }),
      );
    } else {
      this.store.dispatch(
        saveConnection({ connectionType: this.type, connection: { ...this.item, connection_group_id: this.groupId } }),
      );
    }

    this.isConnectionSaved = true;

    this.connectionNewItemSubscriber = this.isSubmitting$
      .pipe(
        filter((isSubmitting) => !isSubmitting),
        withLatestFrom(this.store.select(selectConnectionItem)),
      )
      .subscribe(([, item]) => {
        this.connectionCreated.emit(item);
      });
  }

  testConnection() {
    const accountId = this.route.firstChild?.snapshot.params.account_id;
    let testData;
    const testParams: { id?: number; account_id: string } = {
      account_id: accountId,
    };

    if (this.item.id) {
      testData = {
        account_id: accountId,
        id: this.item.id,
        ...getConnectionData(this.defaultConnection, this.item),
      };
      testParams.id = this.item.id;
    } else {
      testData = { ...this.item };
    }

    this.store.dispatch(testConnection({ connectionType: this.type, connectionId: this.item.id, testData }));
  }

  authenticate() {
    this.connectionType$
      .pipe(first(), withLatestFrom(this.store.select(selectAccountIdNumber)))
      .subscribe(([connectionType, account_id]) => {
        let { auth } = connectionType;
        const options: any = {};

        if (auth === 'shopify') {
          options.shop = this.item.shop;
        }
        if (auth === 'salesforce') {
          options.environment = this.item.environment;
          if (options.environment === SalesforceEnvironment.sandbox) {
            auth = OAuthName.salesforceSandbox;
          }
        }

        options.access_token = this.authService.getToken();
        options.account_id = account_id;

        this.connectionAuthenticationService.authenticate(auth, options).then(
          (res) => {
            // eslint-disable-next-line default-case
            switch (auth) {
              case OAuthName.adwords:
              case OAuthName.analytics:
              case OAuthName.googleCloudSpanner:
              case OAuthName.salesforce:
              case OAuthName.salesforceSandbox:
              case OAuthName.live:
              case OAuthName.bingAds:
              case OAuthName.facebookAdsInsights:
              case OAuthName.facebookAds:
              case OAuthName.intercom:
              case OAuthName.pipedrive:
              case OAuthName.xero:
              case OAuthName.linkedin:
              case OAuthName.instagram:
              case OAuthName.googleSheets:
              case OAuthName.googleDrive:
              case OAuthName.youtube:
              case OAuthName.shopify:
              case OAuthName.tiktokAds:
              case OAuthName.hubspotOAuth:
                if (res.token && !res.config) {
                  this.item.jwt_token = res.token;
                  const data = jwt_decode(res.token) as any;
                  if (data && data.user) this.item.username = data.user.name;
                } else {
                  if (auth === 'shopify') {
                    this.item.hmac = res.config.data.hmac;
                    this.item.timestamp = res.config.data.timestamp;
                  }
                  this.item.code = res.config.data.code;
                  this.item.redirect_uri = res.config.data.redirectUri;
                }
                this.connectionTypeButton.create = true;

                if (!this.item.code && !this.item.jwt_token) {
                  this.connectionTypeButton.create = false;
                  this.errorTexts = [
                    'There was an issue with the authentication process: Not enough data from the provider. Please try again.',
                  ];
                }

                break;
            }
          },
          () => {},
        );
      });
  }

  ngOnDestroy() {
    if (this.errorsSubscriber) {
      this.errorsSubscriber.unsubscribe();
    }
    if (this.successSubscriber) {
      this.successSubscriber.unsubscribe();
    }
    if (this.connectionItemSubscriber) {
      this.connectionItemSubscriber.unsubscribe();
    }
    if (this.connectionNewItemSubscriber) {
      this.connectionNewItemSubscriber.unsubscribe();
    }
  }
}
